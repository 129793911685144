import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './UserNew.css';
import Modal from '../Modal/Modal';
import { checkInput } from '../../helpers/validation';
import CompanySearch from '../CompanySearch/CompanySearch';
import message from "../../constants/message";

const formatMessage = (template, ...values) => {
    return values.reduce((formatted, value) => formatted.replace('%s', value), template);
}

const UserNew = ({ registerUser, getManagementCompanyInfo,loading, error, companyinfo = {} }) => {
    // const location = useLocation();
    const navigate = useNavigate();
    const [login_id, setLoginId] = useState('');
    const [password, setPassword] = useState('');
    const [user_name, setUser_name] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [company_id, setCompany_id] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [email, setEmail] = useState('');
    const [authority_class, setAuthority_class] = useState(0);
    const [errorMessages, setErrorMessages] = useState({});

    useEffect(() => {
        getManagementCompanyInfo();
    }, [getManagementCompanyInfo]);

    useEffect(() => {
        if (Object.keys(companyinfo).length > 0) {
            setCompany_name(companyinfo.company_name);
            setCompany_id(companyinfo.company_id);
        }
        
    }, [companyinfo]);

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        setIs2FAEnabled(checked);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const validateInput = () => {
        const errors = {};
        // Check for empty input fields
        var errmessage = checkInput(login_id, 5, 16, 'ログインID');
        if (errmessage) {
            errors.login_id = errmessage;
        }
        errmessage = checkInput(password, 8, 32, 'パスワード');
        if (errmessage) {

            errors.password = errmessage;
        }

        if (!company_name) {
            errors.company_name = '登録組織は必須項目です。';
        }

        if (user_name.length > 255) {
            errors.user_name = formatMessage(message.MAX_LENGTH_MSG_TT, 'ユーザ名',255);
        }

        // Check if 2FA is enabled and email is empty
        if (is2FAEnabled && !email.trim()) {
            errors.is2FAEnabled = 'メールアドレスを入力してください。';
        }
        // Check if email format is valid
        else if (is2FAEnabled && !isEmailValid(email)) {
            errors.is2FAEnabled = 'メールアドレスが正しくありません。';
        }
        else if (is2FAEnabled && email.length > 50) {
            errors.is2FAEnabled = formatMessage(message.MAX_LENGTH_MSG_TT, 'メールアドレス', 50);
        } 

        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleRegister = async () => {
        if (!validateInput()) {
            return;
        }

        try {
            const payload = {
                "login_id": login_id,
                "company_id": company_id,
                "password": password,
                "user_name": user_name,
                "authority_class": authority_class,
                "otp_enable": is2FAEnabled ? 1 : 0,
                "mail_address": email
            };
            const response = await registerUser(payload);
            setModalMessage(response?.message || "登録しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage("登録に失敗しました。");
            setIsModalOpen(true);
        }
    };

    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleSelect = (item, id) => {
        setCompany_name(item); // Store the selected item
        setCompany_id(id);
    };

    // Validate email format
    const isEmailValid = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <div id='Main' className='MainEdit' >
            <div id='Contents'className='ContentEdit'>
                {/* メッセジー画面 */}
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            if (modalMessage === "登録しました。") {
                                navigate(-1);
                            }
                        }}
                        message={modalMessage}
                    />
                )}
                {/* 組織検索画面 */}
                {showPopup && (<CompanySearch onClose={handleClosePopup} onSelect={handleSelect} />)}
                {/* 組織一覧画面 */}
                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>ユーザ新規登録</span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="UserNew">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    {/* ボタン */}
                                                    <div style={{ width: '95%', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ visibility: 'hidden' }}>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminedit morelong"
                                                                    
                                                                >
                                                                    none
                                                                </button>
                                                            </div>
                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleRegister}
                                                                >
                                                                    登録
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 設定項目 */}
                                                    <dl className="mod-form__set" style={{ marginTop: '40px' }} >
                                                        <dl>
                                                            <dt>登録組織</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="company_name"
                                                                        name="company_name"
                                                                        value={company_name}
                                                                        onChange={(e) => setCompany_name(e.target.value)}
                                                                        readOnly
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.company_name && <span style={{ color: 'red' }}>{errorMessages.company_name}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                            <button type="button" className="mod-btn__roundborder add" style={{ marginLeft: '10px' }} onClick={handleOpenPopup} >
                                                                組織検索
                                                            </button>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>ログインID</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="login_id"
                                                                        name="login_id"
                                                                        value={login_id}
                                                                        onChange={(e) => setLoginId(e.target.value.toLowerCase())}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.login_id && <span style={{ color: 'red' }}>{errorMessages.login_id}</span>}
                                                                    </div>
                                                                </div>

                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>ユーザ名</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="user_name"
                                                                        name="user_name"
                                                                        value={user_name}
                                                                        onChange={(e) => setUser_name(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.user_name && <span style={{ color: 'red' }}>{errorMessages.user_name}</span>}
                                                                    </div>
                                                                </div>

                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>パスワード</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        className="password-mask"
                                                                        id="password"
                                                                        name="password"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.password && <span style={{ color: 'red' }}>{errorMessages.password}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>設定</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <select
                                                                        id="authority_class"
                                                                        name="authority_class"
                                                                        value={authority_class}
                                                                        onChange={(e) => setAuthority_class(e.target.value)}
                                                                    >
                                                                        <option value="0">一般</option>
                                                                        <option value="1">管理（一般）</option>
                                                                        <option value="9">管理（システム）</option>
                                                                    </select>
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt className="double">二段階認証</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                                                    <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} checked={is2FAEnabled} onChange={handleCheckboxChange} />
                                                                    <span style={{ marginRight: '10px' }}></span>
                                                                    二段階認証を使用する
                                                                </label>

                                                                <div className="mod-form__company">
                                                                    <input
                                                                        type="text"
                                                                        name="mail_2fa"
                                                                        value={email}
                                                                        onChange={handleEmailChange}
                                                                        readOnly={!is2FAEnabled}
                                                                        style={{ background: is2FAEnabled ? '#fff' : 'lightgray' }} 
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.is2FAEnabled && <span style={{ color: 'red' }}>{errorMessages.is2FAEnabled}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserNew.propTypes = {
    registerUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    getManagementCompanyInfo: PropTypes.func.isRequired,
    error: PropTypes.string
    // companies: PropTypes.array,
    // fetchCompanyList: PropTypes.func
};

export default React.memo(UserNew);