import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Login from "../components/Login/Login";
import { sendLoginInformation, fetchUserData, CheckOtp,resendOtp } from "../actions/user";

const mapStateToProps = (state) => {
  const { login } = state;
  const {
    isFetching,
    otpToken,
    accessToken,
    refreshToken,
    otpEnabled,
    errorCode,
    errorMessage,
    otpAttemptCount
  } = login || {
    isFetching: true,
    otpToken:"",
    accessToken: "",
    refreshToken: "",
    otpEnabled: false,
    errorCode: null,
    errorMessage: "",
    otpAttemptCount: 0
  };

  return {
    isFetching,
    otpToken,
    accessToken,
    refreshToken,
    otpEnabled,
    errorCode,
    errorMessage,
    otpAttemptCount
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendLoginInformation,
      fetchUserData,
      CheckOtp,
      resendOtp,  
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
