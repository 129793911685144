import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import MenuContainer from '../containers/MenuContainer';
import LoginContainer from '../containers/LoginContainer';
import FacilityNewContainer from '../containers/FacilityNewContainer';
import FacilityListContainer from '../containers/FacilityListContainer';
import FacilityEditContainer from '../containers/FacilityEditContainer';
import MailListContainer from '../containers/MailListContainer';
import MailEditContainer from '../containers/MailEditContainer';
import MaintenanceListContainer from '../containers/MaintenanceListContainer';
import MaintenanceNewContainer from '../containers/MaintenanceNewContainer';
import DeletedLogListContainer from '../containers/DeletedLogListContainer';
import DeletedLogEditContainer from '../containers/DeletedLogEditContainer';
import ChangePasswordContainer from '../containers/ChangePasswordContainer';
import AdminListContainer from '../containers/AdminListContainer';
import AdminNewContainer from '../containers/AdminNewContainer';
import AdminEditContainer from '../containers/AdminEditContainer';
import UserListContainer from '../containers/UserListContainer';
import UserNewContainer from '../containers/UserNewContainer';
import UserEditContainer from '../containers/UserEditContainer';
import CameraListContainer from '../containers/CameraListContainer';
import CameraEditContainer from '../containers/CameraEditContainer';
import CompanyListContainer from '../containers/CompanyListContainer';
import CompanyEditContainer from '../containers/CompanyEditContainer';
import PageNotFound from '../components/error_404';

import "../App/App.css"

const App = () => {
  const location = useLocation();
  const authorityClass = parseInt(localStorage.getItem("authorityClass"), 10);
  const allowedRoutes = [
    "/",
    "/AdminMaster",
    "/AdminNew",
    "/AdminEdit",
    "/CompanyMaster",
    "/CompanyEdit",
    "/UserMaster",
    "/UserNew",
    "/UserEdit",
    "/passwordChangeDisplay",
    "/CameraGaugeMaster",
    "/CameraChange",
    "/FacilityMaster",
    "/FacilityNew",
    "/FacilityEdit",
    "/MailMaster",
    "/MailEdit",
    "/MaintenanceMaster",
    "/MaintenanceNew",
    "/DeletedLogMaster",
    "/DeletedLogEdit",
    "/login",
  ];
  const isAuthorityRestrictedPath = location.pathname.startsWith('/Maintenance') 
                            || location.pathname.startsWith('/DeletedLog')
                            || location.pathname.startsWith('/deletedLogEdit') 
                            || location.pathname.startsWith('/*');

  const isUnknownPath = !allowedRoutes.includes(location.pathname); 

  const hideMenu = isUnknownPath || (isAuthorityRestrictedPath && authorityClass !== 1) || location.pathname === '/login';

  return (
    <div className="main-container">
      {!hideMenu && <MenuContainer />}
      <Routes>
        <Route path="/" element={
          <PrivateRoute>
            <AdminListContainer />
          </PrivateRoute>
        } />
        {/* 管理者マスタ */}
        <Route path="/AdminMaster" element={
          <PrivateRoute>
            <AdminListContainer />
          </PrivateRoute>
        } />
        <Route path="/AdminNew" element={
          <PrivateRoute>
            <AdminNewContainer />
          </PrivateRoute>
        } />
        <Route path="/AdminEdit" element={
          <PrivateRoute>
            <AdminEditContainer />
          </PrivateRoute>
        } />

        {/* 管理者マスタ */}
        <Route path="/CompanyMaster" element={
          <PrivateRoute>
            <CompanyListContainer />
          </PrivateRoute>
        } />
        <Route path="/CompanyEdit" element={
          <PrivateRoute>
            <CompanyEditContainer />
          </PrivateRoute>
        } />

        {/* ユーザマスタ */}
        <Route path="/UserMaster" element={
          <PrivateRoute>
            <UserListContainer />
          </PrivateRoute>
        } />

        <Route path="/UserNew" element={
          <PrivateRoute>
            <UserNewContainer />
          </PrivateRoute>
        } />
        <Route path="/UserEdit" element={
          <PrivateRoute>
            <UserEditContainer />
          </PrivateRoute>
        } />

        <Route path="/passwordChangeDisplay" element={
          <PrivateRoute>
            <ChangePasswordContainer />
          </PrivateRoute>
        } />

        <Route path="/CameraGaugeMaster" element={
          <PrivateRoute>
            <CameraListContainer />
          </PrivateRoute>
        } />
        <Route path="/CameraChange" element={
          <PrivateRoute>
            <CameraEditContainer />
          </PrivateRoute>
        } />

        {/* 施設マスタ */}

        <Route path="/FacilityMaster" element={
          <PrivateRoute>
            <FacilityListContainer />
          </PrivateRoute>
        } />

        <Route path="/FacilityNew" element={
          <PrivateRoute>
            <FacilityNewContainer />
          </PrivateRoute>
        } />
        
        <Route path="/FacilityEdit" element={
          <PrivateRoute>
            <FacilityEditContainer />
          </PrivateRoute>
        } />

        {/* メールマスタ */}
        <Route path="/MailMaster" element={
          <PrivateRoute>
            <MailListContainer />
          </PrivateRoute>
        } />
        <Route path="/MailEdit" element={
          <PrivateRoute>
            <MailEditContainer />
          </PrivateRoute>
        } />
        {/* メンテナンスマスタ */}
        <Route path="/MaintenanceMaster" element={
          <PrivateRoute requiredAuthority={1} >
            <MaintenanceListContainer />
          </PrivateRoute>
        } />
        <Route path="/MaintenanceNew" element={
          <PrivateRoute requiredAuthority={1}>
            <MaintenanceNewContainer />
          </PrivateRoute>
        } />
        {/* 削除組織ログ */}
        <Route path="/DeletedLogMaster" element={
          <PrivateRoute requiredAuthority={1}>
            <DeletedLogListContainer />
          </PrivateRoute>
        } />

        <Route path="/DeletedLogEdit" element={
          <PrivateRoute requiredAuthority={1} >
            <DeletedLogEditContainer />
          </PrivateRoute>
        } />

        <Route path="*" element={<PageNotFound />} />

        {/* ログイン */}
        <Route path="/login" element={<LoginContainer />} />
        
      </Routes>

    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
