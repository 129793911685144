import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";
import {utf8ToBase64} from "../helpers/validation";


export const fetchUserRequest = () => ({
    type: ActionTypes.FETCH_USERS_REQUEST,
});

export const fetchUserSuccess = (admins) => ({
    type: ActionTypes.FETCH_USERS_SUCCESS,
    payload: admins
});

export const fetchUserFailure = (error) => ({
    type: ActionTypes.FETCH_USERS_FAILURE,
    payload: error
});


export const fetchUsers = (pageindex, itemsPerPage, company_code, company_name, login_id, user_name) => {
    return async (dispatch) => {
        dispatch(fetchUserRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                const maxLength = 255;
                if (company_code) {
                    const trimmed_company_code = company_code.length > maxLength ? company_code.slice(0, maxLength) : company_code;
                    const encoded_company_code = encodeURIComponent(utf8ToBase64(trimmed_company_code));
                    strFilter += `&company_code=${encoded_company_code}`;
                }
                if (company_name) {
                    const trimmed_company_name = company_name.length > maxLength ? company_name.slice(0, maxLength) : company_name;
                    const encoded_company_name = encodeURIComponent(utf8ToBase64(trimmed_company_name));
                    strFilter += `&company_name=${encoded_company_name}`;
                }
                if (login_id) {
                    const trimmed_login_id = login_id.length > maxLength ? login_id.slice(0, maxLength) : login_id;
                    const encoded_login_id = encodeURIComponent(utf8ToBase64(trimmed_login_id));
                    strFilter += `&login_id=${encoded_login_id}`;
                }
                if (user_name) {
                    const trimmed_user_name = user_name.length > maxLength ? user_name.slice(0, maxLength) : user_name;
                    const encoded_user_name = encodeURIComponent(utf8ToBase64(trimmed_user_name));
                    strFilter += `&user_name=${encoded_user_name}`;
                }
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + `path=user&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}` + strFilter, 
                    {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchUserSuccess(data));
            }
            else {
                const data = {"data_list": [{ "user_id": 1, "company_id": 1, "company_code": "hakaru", "company_name": "ハカルプラス", "login_id": "hathiminhhuong", "user_name": "HATHIMINHHUONG", "authority_class": 0, "otp_enable": 0, "mail_address": "hathiminhhuong@hakaru.jp"},
                { "user_id": 2, "company_id": 1, 'company_code': 'hakaru2', 'company_name': '東京都水道局', 'login_id': 'tokyo.jp.test2', 'user_name': 'tokyo.jp.test2 (管理システム）',  "authority_class": 1, "otp_enable": 0, "mail_address": "hathiminhhuong@hakaru.jp" },
                { "user_id": 3, "company_id": 1, 'company_code': 'hakaru', 'company_name': '東京都水道局', 'login_id': 'rental.test1', 'user_name': 'rental.test1', "authority_class": 1, "otp_enable": 0, "mail_address": "hathiminhhuong@hakaru.jp"},
                { "user_id": 4, "company_id": 1, 'company_code': 'hakaru', 'company_name': 'セイリツ工業株式会社', 'login_id': 'tokyo.jp.test1', 'user_name': 'tokyo.jp.test1 (管理一般）', "authority_class": 9, "otp_enable": 0, "mail_address": "hathiminhhuong@hakaru.jp"},
                { "user_id": 5, "company_id": 1, 'company_code': 'hakaru', 'company_name': '鴻池運輸株式会社', 'login_id': 'tuyendothanh', 'user_name': 'TUYEN DOTHANH', "authority_class": 9, "otp_enable": 0, "mail_address": "hathiminhhuong@hakaru.jp"}
                ], "all_data_count": 5};
                dispatch(fetchUserSuccess(data));
            }


        } catch (error) {
            dispatch(fetchUserFailure(error.message));
        }
    };
};
