import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";
import {utf8ToBase64} from "../helpers/validation";

export const fetchCompanyRequest = () => ({
    type: ActionTypes.FETCH_COMPANIES_REQUEST,
});

export const fetchCompanySuccess = (companies) => ({
    type: ActionTypes.FETCH_COMPANIES_SUCCESS,
    payload: companies
});

export const fetchCompanyFailure = (error) => ({
    type: ActionTypes.FETCH_COMPANIES_FAILURE,
    payload: error
});


export const fetchCompany = (pageindex, itemsPerPage, company_code = '', company_name = '') => {
    return async (dispatch) => {
        dispatch(fetchCompanyRequest());
        try {
            let strFilter = "";
            const maxLength = 255;
            if (company_code) {
                const trimmed_company_code = company_code.length > maxLength ? company_code.slice(0, maxLength) : company_code;
                const encoded_company_code = encodeURIComponent(utf8ToBase64(trimmed_company_code));
                strFilter += `&company_code=${encoded_company_code}`;
            }
            if (company_name) {
                const trimmed_company_name = company_name.length > maxLength ? company_name.slice(0, maxLength) : company_name;
                const encoded_company_name = encodeURIComponent(utf8ToBase64(trimmed_company_name));
                strFilter += `&company_name=${encoded_company_name}`;
            }
            const accessToken = localStorage.getItem('accessTokenCompany');
            const url = `${BASE_URL}path=company&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}${strFilter}`;
            const response = await axiosInstance.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            const data = response.data.respons || { "data_list": [], "all_data_count": 0 };
            dispatch(fetchCompanySuccess(data));
        } catch (error) {
            dispatch(fetchCompanyFailure(error.message));
        }
    };
};

