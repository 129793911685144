import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    cameras: [],
    error: null,
    alldatanumber: 0,
    is_null: false
};

const cameraListReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CAMERA_LIST_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_CAMERA_LIST_SUCCESS:
            return { ...state, loading: false, cameras: action.payload.data_list, alldatanumber: action.payload.all_data_count, is_null: action.payload.data_list.length === 0};
        case ActionTypes.FETCH_CAMERA_LIST_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default cameraListReducer;
