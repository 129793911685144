import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";
import {utf8ToBase64} from "../helpers/validation";

//メールリストのダウンロード要求を開始する
export const fetchMailRequest = () => ({
    type: ActionTypes.FETCH_MAIL_REQUEST,
});

//メールリストのダウンロード時に正常に処理されました
export const fetchMailSuccess = (mail) => ({
    type: ActionTypes.FETCH_MAIL_SUCCESS,
    payload: mail,
});

//メールリストのダウンロード時の失敗の処理
export const fetchMailFailure = (error) => ({
    type: ActionTypes.FETCH_MAIL_FAILURE,
    payload: error,
});

//メールリストを読み込む機能
export const fetchMail = (pageindex,itemsPerPage,company_code,company_name) => {
    return async (dispatch) => {
        dispatch(fetchMailRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                const maxLength = 255;
                if (company_code) {
                    const trimmed_company_code = company_code.length > maxLength ? company_code.slice(0, maxLength) : company_code;
                    const encoded_company_code = encodeURIComponent(utf8ToBase64(trimmed_company_code));
                    strFilter += `&company_code=${encoded_company_code}`;
                }
                if (company_name) {
                    const trimmed_company_name = company_name.length > maxLength ? company_name.slice(0, maxLength) : company_name;
                    const encoded_company_name = encodeURIComponent(utf8ToBase64(trimmed_company_name));
                    strFilter += `&company_name=${encoded_company_name}`;
                }

                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL +`path=mail&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}` + strFilter, 
                    {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchMailSuccess(data));
            }
            else {
                const data ={"data_list": [{ 
                    'company_code': 'hakaru_rental', 
                    'company_name': 'デモ会社', 
                    'mail_to_list': [ "test1@gmail.com"],
                    'mail_cc_list': ["test@gmail.com", ] , 
                    'mail_bcc_list': ["test@gmail.com", "test1@gmail.com"]},
                { 
                    'company_code': 'kamimaru', 
                    'company_name': 'デモ会社', 
                    'mail_to_list': ["test@gmail.com", "test1@gmail.com"], 
                    'mail_cc_list': ["test@gmail.com", "test1@gmail.com"] , 
                    'mail_bcc_list': []}
                ], "all_data_count": 5};
                dispatch(fetchMailSuccess(data));
            }
        } catch (error) {
            dispatch(fetchMailFailure(error.message));
        }
    };
};
