import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


function SideMenu() {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const userType = queryParams.get('userType');
    const isAdminActive = location.pathname.startsWith('/Admin') || (location.pathname === '/passwordChangeDisplay' && userType === 'admin');
    const isUserActive = location.pathname.startsWith('/User') || (location.pathname === '/passwordChangeDisplay' && userType === 'user');
    const authorityClass = useSelector(state => state.login.user ? state.login.user.authorityClass : '');

    useEffect(() => {
        if (authorityClass) {
            localStorage.setItem('authorityClass', authorityClass);
         }
      }, [authorityClass]);
      
    useEffect(() => {
        const localTestName = localStorage.getItem('authorityClass');
        if (localTestName && !authorityClass) {
        }
    }, [dispatch, authorityClass]);
    
    return (
        <div className="lay-sidemenu">
            
            <div className="lay-sidemenu-wrap">

                <Link className={`lay-sidemenu__set ${isAdminActive ? 'menu_active' : ''}`}
                    to="/AdminMaster">管理者マスタ
                </Link>
                
                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/Company') ? 'menu_active' : ''}`}
                    to="/CompanyMaster"> 組織マスタ
                </Link>

                <Link
                    className={`lay-sidemenu__set ${isUserActive ? 'menu_active' : ''}`}
                    to="/UserMaster"> ユーザマスタ
                </Link>

                <Link className={`lay-sidemenu__set ${location.pathname.startsWith('/Facility') ? 'menu_active' : ''}`}
                    to="/FacilityMaster"> 施設マスタ
                </Link>


                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/Camera') ? 'menu_active' : ''}`}
                    to="/CameraGaugeMaster"> カメラー計器マスタ
                </Link>

                <Link
                    className={`lay-sidemenu__set ${location.pathname.startsWith('/Mail') ? 'menu_active' : ''}`}
                    to="/MailMaster"> メールマスタ
                </Link>

                {authorityClass === 1 && (
                    <>
                        <Link className={`lay-sidemenu__set ${location.pathname.startsWith('/Maintenance') ? 'menu_active' : ''}`}
                            to="/MaintenanceMaster"> メンテナンスマスタ
                        </Link>

                        <Link className={`lay-sidemenu__set ${location.pathname.startsWith('/DeletedLog') ? 'menu_active' : ''}`}
                            to="/DeletedLogMaster"> ログ表示
                        </Link>
                    </>
                )}
            </div>

        <div className="menu-footer">
            <img
                src="/img/copyright.png"
                alt="Hakaru+ Copyright(C) HAKARU PLUS Corporation. All Rights Reserved."
                className="footer-image"
            />
        </div>
    </div>
    );
}

export default SideMenu;

