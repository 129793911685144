import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const Header = ({ logout }) => {
  const dispatch = useDispatch();
  //// Redux 状態から管理者名を取得します。そうでない場合は、空の文字列を返します。
  const admin_name = useSelector(state => state.login.user ? state.login.user.admin_name : '');

  // admin_name が更新されたときに管理者名を localStorage に保存します
  useEffect(() => {
    if (admin_name) {
      localStorage.setItem('admin_name', admin_name);
    }
  }, [admin_name]);
  
  // adminName が存在しないが localStorage には存在する場合を処理する
  useEffect(() => {
    const localAdminName = localStorage.getItem('admin_name');
    if (localAdminName && !admin_name) {
    }
  }, [dispatch, admin_name]);

  // ログアウト イベントを処理し、localStorage から管理者名を削除します
  const handleLogout = () => {
    logout();
    // localStorage.removeItem('admin_name'); 
    // localStorage.removeItem('authorityClass'); 

  };

  //現在のユーザー情報
  return (
    <nav className="navbar">
      <div className="container-head d-flex justify-content-between align-items-center">
        <span className="rounded overcutoff">巡回点検楽スルー管理画面</span>
        <div className="d-flex align-items-center">
          <span className="mr-3">  { admin_name || localStorage.getItem('admin_name') ? `(${admin_name || localStorage.getItem('admin_name')})` : '()'}</span>
          <span className="logout-container d-flex align-items-center cursor-pointer" onClick={handleLogout}>
            <span id="idloginnamedisplay" className="text-dark nav-link pl-1">ログアウト</span>
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Header;
