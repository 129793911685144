import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation,useNavigate } from 'react-router-dom';
import './MaintenanceNew.css';
import Modal from '../Modal/Modal';

//メンテナンス情報の新規登録
const MaintenanceNew = ({ registerMaintenance,companynew = [], loading, error, fetchcompanynew, alldatanumber }) => {
    const itemsPerPage = 20;
    const [maintenancenew, setmaintenancenew] = useState([{}]);
    const [pageindex, setPageIdx] = useState(1);
    const [authorityCommonFlag, setAuthorityCommonFlag] = useState(true);
    const [authorityAdminFlag, setAuthorityAdminFlag] = useState(true);
    const [screenMessage, setScreenMessage] = useState("");
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const location = useLocation(); 
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [search, setSearch] = useState({ company_code: '', company_name: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    //新しい企業リストをダウンロード
    useEffect(() => {
        fetchcompanynew(pageindex, itemsPerPage)
            .finally(() => {
                setIsDataLoaded(true);
            });
    }, [fetchcompanynew, pageindex, itemsPerPage,location]);
    
    // 新しいメンテナンスリストを更新します
    useEffect(() => {
        if (companynew) {
            setmaintenancenew(companynew);
        }
    }, [companynew]);

    // チェックボックスが変更されたときの処理
    const handleCheckboxChange = (companyId, isChecked) => {
        if (isChecked) {
            setSelectedCompanies(prev => [...prev, companyId]);
        } else {
            setSelectedCompanies(prev => prev.filter(id => id !== companyId));
        }
    };

    // 検索時の処理
    const handleSearchSubmit = async (event) => {
        if (event) event.preventDefault();
        setSelectedCompanies([]);
        await fetchcompanynew(pageindex, itemsPerPage, search.company_code, search.company_name);
    };

    // すべての会社を選択または選択解除します
    const toggleSelectAll = () => {
        if (selectedCompanies.length === maintenancenew.length) {
            setSelectedCompanies([]);
        } else {
            setSelectedCompanies(maintenancenew.map(val => val.company_id));
        }
    };

    // 前のページに戻る
    const handleBackClick = () => {
        navigate(-1);
    };

    // 検索値が変化したときの処理
    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    // 新規メンテナンス登録処理
    const handleRegister = async () => {
        if (!screenMessage.trim()) {
            setErrors({ screenMessage: "メッセージを入力してください。" });
            return;
        } else if (screenMessage.trim().length > 100) {
            setErrors({ screenMessage: 'メッセージを100文字以下で入力してください。' });
            return;
        }
    
        try {
            const payload = {
                company_id_list: selectedCompanies,
                authority_admin_flag: authorityAdminFlag ? 1 : 0,
                authority_common_flag: authorityCommonFlag ? 1 : 0,
                screen_message: screenMessage
            };
            const response = await registerMaintenance(payload);
            setModalMessage(response?.message || "登録しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage("登録に失敗しました。");
            setIsModalOpen(true);
        }
    };

    //ページネーションを表示。
    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        // ページネーションインターフェイスをレンダリングします
        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                            <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                                <span>{page}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };
         
    //ページネーションでのページ変更イベントの処理
    const handlePageChange = (page) => {
        setPageIdx(page); 
        setCurrentPage(page);
        fetchcompanynew(page, itemsPerPage, search.company_code, search.company_name);
    };

    return (
        <div id='Main' className='MainMail'>
            <div id='Contents' className='ContentsMail' >
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            navigate(-1); 
                        }}
                        message={modalMessage}
                    />
                )}

                {!isDataLoaded ? (
                    <div className="loading-container">
                        <div className="overlay active">
                            <div className="spinner active">
                                <img id="waiting-img" src="img/loader.gif" alt="Loading..." />
                            </div>
                        </div>
                    </div>
                ) : (

                <div id="ajax-view-disp">
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メンテナンス新規登録</span>
                                </div>

                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '20px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name"value={search.company_name} onChange={handleSearchChange}autoComplete="off"  />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>
                                </form>

                                <div className="lay-master-set__frame" >
                                    <div className="lay-master__table">
                                        <div  style={{ display: 'flex', alignItems: 'center',  width: '100%' }}>
                                            <div className="mod-form-graph__set" style={{ width: '100%' }}>
                                                <dl>
                                                    <dt>メッセージ</dt>
                                                    <dd>
                                                        <div className='mod-form-maintenance__text'>
                                                            <input type="text" value={screenMessage} onChange={(e) => {
                                                                setScreenMessage(e.target.value);
                                                                setErrors(prev => ({
                                                                ...prev,
                                                                screenMessage: e.target.value.trim() ? '' : 'メッセージを入力してください。'
                                                                }));
                                                                }}
                                                                style={{ height: '35px'}}
                                                            />
                                                            {errors.screenMessage && (
                                                                <div className="error_message" style={{ color: 'red', marginTop: '10px'}}>
                                                                    {errors.screenMessage}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </dd>
                                                </dl>
                                            </div>

                                            <ul style={{ display: 'flex',  gap: '40px', listStyle: 'none', marginLeft: '5%'  }}>
                                                <li>
                                                    <button type="button" className="mod-btn__roundborder add" onClick={handleRegister}
                                                        disabled={!selectedCompanies.length || (!authorityAdminFlag && !authorityCommonFlag)}
                                                    > 登録
                                                    </button>    
                                                </li>
    
                                                <li>
                                                    <button type="submit" className="mod-btn__adminback back" onClick={handleBackClick} >戻る</button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="mt20" style={{ marginTop: '25px', display: 'flex', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', marginRight: '10px' }}> 
                                                    <input type="checkbox" className="checkbox-input" checked={authorityCommonFlag} onChange={(e) => setAuthorityCommonFlag(e.target.checked)}
                                                    style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} /> 一般
                                                </label>
    
                                                <label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                                    <input type="checkbox" className="checkbox-input" checked={ authorityAdminFlag} onChange={(e) => setAuthorityAdminFlag (e.target.checked)}               
                                                        style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} /> 管理者（一般）
                                                </label>
                                            </div>

                                            <label id="check_all" style={{ color: 'blue', marginLeft: 'auto', cursor: 'pointer' }} onClick={toggleSelectAll}>
                                                全てのチェックを操作
                                            </label>                                        
                                        </div>

                                        <form id="MaintenanceNew"style={{ marginTop: '10px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-maintenancenew-cot1">組織ID</th>
                                                        <th className="col-maintenancenew-cot2">組織名</th>
                                                        <th className="col-maintenancenew-cot3">選択</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    {maintenancenew.length > 0 ? (
                                                        maintenancenew.map((val,index) => (
                                                            <tr key={index} className="lay-tr">
                                                                <td className="col-maintenancenew-cot1" style={{ textAlign: 'left' }} >{val.company_code}</td>
                                                                <td className="col-maintenancenew-cot2" style={{ textAlign: 'left' }} >{val.company_name}</td>
                                                                <td className="col-maintenancenew-cot3" >
                                                                    <input type="checkbox" checked={selectedCompanies.includes(val.company_id)}                                    
                                                                         onChange={(e) => handleCheckboxChange(val.company_id, e.target.checked)} className="checkbox-input" style={{ WebkitAppearance: 'checkbox' }} 
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="3" style={{ textAlign: "center" }}>
                                                                <div className="mod-no-data">表示データが存在しません。</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </form>
                                         {/*テーブルのページ */}
                                         {totalPages > 1 && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                )}
            </div>
        </div>
    );
};

// propsのデータ型を確認する
MaintenanceNew.propTypes = {
    registerMaintenance: PropTypes.func.isRequired,
    companynew: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchcompanynew: PropTypes.func.isRequired
};

export default React.memo(MaintenanceNew);