import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  deletedLog: [],
  error: null,
  alldatanumber: 0, 
  is_null: false,

};
const deletedListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DELETEDLOG_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_DELETEDLOG_SUCCESS:
      return {...state,loading: false,deletedLog: action.payload.data_list, alldatanumber: action.payload.all_data_count, error: null, is_null: action.payload.data_list.length === 0,};
    case ActionTypes.FETCH_DELETEDLOG_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default deletedListReducer;
