import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";
import {utf8ToBase64} from "../helpers/validation";

//メンテナンスリストの入手リクエスト
export const fetchMaintenanceRequest = () => ({
    type: ActionTypes.FETCH_MAINTENANCE_REQUEST,
});

//メンテナンスリスト受信時に正常に処理されました
export const fetchMaintenanceSuccess = (maintenance) => {
    return {
        type: ActionTypes.FETCH_MAINTENANCE_SUCCESS,
        payload: maintenance
    };
};

//メンテナンスリスト取得時の失敗処理
export const fetchMaintenanceFailure = (error) => ({
    type: ActionTypes.FETCH_MAINTENANCE_FAILURE,
    payload: error
});
	
//メンテナンスリストをロードする機能
export const fetchMaintenance = (pageindex, itemsPerPage, company_code, company_name) => {
    return async (dispatch) => {
        dispatch(fetchMaintenanceRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                const maxLength = 255;
                if (company_code) {
                    const trimmed_company_code = company_code.length > maxLength ? company_code.slice(0, maxLength) : company_code;
                    const encoded_company_code = encodeURIComponent(utf8ToBase64(trimmed_company_code));
                    strFilter += `&company_code=${encoded_company_code}`;
                }
                if (company_name) {
                    const trimmed_company_name = company_name.length > maxLength ? company_name.slice(0, maxLength) : company_name;
                    const encoded_company_name = encodeURIComponent(utf8ToBase64(trimmed_company_name));
                    strFilter += `&company_name=${encoded_company_name}`;
                }
                
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + `path=maintenance&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}` + strFilter, 
                    {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchMaintenanceSuccess(data));
            }
            else {
                const data = {"data_list": [{ "company_id": 1, "company_code": "hpdemo", "company_name": "デモ会社	","screen_message": "テスト", "view_user_authority": 1},
                { "company_id": 2, 'company_code': 'hpdemo',"company_name": "デモ会社	", 'screen_message': 'テスト', "view_user_authority": 1},
                { "company_id": 3, 'company_code': 'hpdemo', 'screen_message': 'テスト', "view_user_authority": 1},
                { "company_id": 4, 'company_code': 'hpdemo', 'screen_message': 'テスト', "view_user_authority": 1},
                {"company_id": 5, 'company_code': 'hpdemo', 'screen_message': 'テスト', "view_user_authority": 1}
                ], "all_data_count": 5};
                dispatch(fetchMaintenanceSuccess(data));
            }


        } catch (error) {
            dispatch(fetchMaintenanceFailure(error.message));
        }
    };
};

//メンテナンス削除リクエストを開始する
export const fetchMaintenanceDeleteRequest = () => ({
    type: ActionTypes.MAINTENANCE_DELETE_REQUEST
});

//メンテナンスの削除時に正常に処理されました
export const fetchMaintenanceDeleteSuccess = (maintenance) => ({
    type: ActionTypes.MAINTENANCE_DELETE_SUCCESS,
    payload: maintenance
});

//メンテナンス削除時の失敗の処理
export const fetchMaintenanceDeleteFailure = (error) => ({
    type: ActionTypes.MAINTENANCE_DELETE_FAILURE,
    payload: error
});

// メンテナンス削除を行う関数
export const deletemaintenance = (payload) => {
    return async (dispatch) => {
        dispatch(fetchMaintenanceDeleteRequest());
        try {
            if (!window.debug) {
            const accessToken = localStorage.getItem("accessToken");

            const response = await axiosInstance.post(BASE_URL + "path=maintenance&type=delete-data", payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });

            if (response.data.status === 200) {
                const message = "削除しました。";
                dispatch(fetchMaintenanceDeleteSuccess(message));
                return message;
            }
            return response;
        } else {
            const msg = "完全に削除しました。";
            var response = {};
            response["data"] = { "respons": { "msg": msg } }
            dispatch(fetchMaintenanceDeleteSuccess(msg));
            return response;
        } 
    } catch (error) {
            dispatch(fetchMaintenanceDeleteFailure(error.message));
        }
    };
};

// メンテナンス更新リクエストを開始します
export const updatemaintenanceRequest = () => ({
    type: ActionTypes.MAINTENANCE_UPDATE_REQUEST,
});

// メンテナンスアップデート中に正常に処理されました
export const updatemaintenanceSuccess = (message) => ({
    type: ActionTypes.MAINTENANCE_UPDATE_SUCCESS,
    payload: message,
});

// メンテナンス更新中の失敗を処理します
export const updatemaintenanceFailure = (error) => ({
    type: ActionTypes.MAINTENANCE_UPDATE_FAILURE,
    payload: error,
});

// メンテナンス情報更新機能
export const submitMaintenance = (maintenance) => {
    return async (dispatch) => {
        dispatch(updatemaintenanceRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=maintenance&type=update-info-data', maintenance, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                let message = response.data.respons;
                if (response.data.status === 200) {
                    message = "変更しました。"; 
                }
                dispatch(updatemaintenanceSuccess(message));
                return { message }; 
            } else {
                const msg = "変更しました。";
                // const response = { data: { respons: msg } };
                dispatch(updatemaintenanceSuccess(msg));
                return { message: msg };
            }
        } catch (error) {
            const message = error.response?.message || "登録に失敗しました。";
            dispatch(updatemaintenanceFailure(message));
            return { message };
        }
    };
};

export const fetchDetailRequest = () => ({
    type: ActionTypes.FETCH_MAINTENANCEDETAIL_REQUEST,
});

export const fetchDetailSuccess = (detail) => ({
    type: ActionTypes.FETCH_MAINTENANCEDETAIL_SUCCESS,
    payload: detail.respons  
});

export const fetchDetailFailure = (error) => ({
    type: ActionTypes.FETCH_MAINTENANCEDETAIL_FAILURE,
    payload: error
});

export const fetchDetail = (companyId, viewUserAuthority) => {
    return async (dispatch) => {
        dispatch(fetchDetailRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(`${BASE_URL}path=maintenance&type=get-data&id=${companyId}&view_user_authority=${viewUserAuthority}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data || {};
                dispatch(fetchDetailSuccess(data));
                return response;
            } else {
                const data = {
                    "company_id": 2595790,
                    "screen_message": "test",
                    "view_user_authority": 0
                };
                dispatch(fetchDetailSuccess(data));
                return data;
            }
        } catch (error) {
            dispatch(fetchDetailFailure(error.message));
        }
    };
};


