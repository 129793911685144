import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DeletedEdit from "../components/DeletedLogEdit";
import { fetchdeletedlogedit,fetchdeletedlogeditsmg } from "../actions/deletedlogedit";

const mapStateToProps = (state) => {
  return {
    deletedlogedit: state.deletedlogedit.deletedLog,
    logMessages: state.deletedlogedit.logMessages, 
    loading: state.deletedlogedit.loading,
    error: state.deletedlogedit.error,
    is_null: state.deletedlogedit.is_null
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchdeletedlogedit,
      fetchdeletedlogeditsmg,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeletedEdit);