// refreshTokenMiddleware.js
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { store } from './store';
import { logout } from '../actions/user';
import BASE_URL from "../constants/Api";

// Create an axios instance
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
}, error => Promise.reject(error));

const shouldRefreshToken = error => error.response && error.response.status === 401;

const refreshAuthLogic = async failedRequest => {
    const refreshToken = localStorage.getItem('refreshToken');
    
    if (!refreshToken) {
        handleLogout();
        return Promise.reject(new Error('No refresh token found'));
    }
    try {
        const response = await axios.post(`${BASE_URL}path=refresh-token`, { refresh_token: refreshToken }, {
            headers: { Authorization: `Bearer ${refreshToken}` }
        });
        const access_token = response.data.respons.authToken;
        const refresh_token = response.data.respons.refresh_token;
        localStorage.setItem('accessToken', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        failedRequest.headers['Authorization'] = `Bearer ${access_token}`;
        return Promise.resolve();
    } catch (error) {
        console.error('Failed to refresh token', error);
        handleLogout();
        return Promise.reject(error);
    }
};

const handleLogout = () => {
    store.dispatch(logout());
    localStorage.clear();
    window.location.href = '/login';
};

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, { shouldRefresh: shouldRefreshToken });

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response.status === 501 && originalRequest.url === `${BASE_URL}path=refresh-token`) {
            handleLogout();
            return Promise.reject(error);
        }

        if (error.response.status !== 502 || originalRequest._retry) {
            return Promise.reject(error);
        }

        originalRequest._retry = true;
        try {
            await refreshAuthLogic(originalRequest);
            originalRequest.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
            return axiosInstance(originalRequest);
        } catch (err) {
            console.error('Failed to retry request after refreshing token', err);
            handleLogout();
            return Promise.reject(err);
        }
    }
);

export default axiosInstance;
