import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CameraList from '../components/CameraList';
import { fetchCameras } from '../actions/cameralist';

const mapStateToProps = (state) => {
    const { cameraList } = state;
    return {
        cameras: cameraList.cameras,
        loading: cameraList.loading,
        error: cameraList.error,
        alldatanumber: cameraList.alldatanumber,
        is_null: cameraList.is_null
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCameras
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CameraList);