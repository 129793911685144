import React from 'react';
import './error_404.css';

const PageNotFound = () => {
  return (
    <div className="page-not-found-container">
      <div className="page-not-found-box">
        <h2 className="page-not-found-title">404 Page Not Found</h2>
        <p className="page-not-found-text">
            The page you requested was not found.
        </p>
        <a href="/" className="page-not-found-link">
            Go back
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
