import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CompanyList from '../components/CompanyList';
import { fetchCompanies, fetchManagementUnitData } from '../actions/companylist';

const mapStateToProps = (state) => {
    const { companyList } = state;
    return {
        companies: companyList.companies,
        // managementUnitData: companyList.managementUnitData,
        loading: companyList.loading,
        error: companyList.error,
        alldatanumber: companyList.alldatanumber,
        is_null: companyList.is_null
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCompanies,
    fetchManagementUnitData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList);