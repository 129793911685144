import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import GaugeEdit from './CameraChangeSearch';
import { ja } from 'date-fns/locale';
import { format } from 'date-fns';
import './CameraChange.css';
import message from "../../constants/message";
import Modal from "../Modal/Modal"

const customJa = {
    ...ja,
    options: {
        ...ja.options,
        weekStartsOn: 1
    }
};

const NOT_FOUND_IMG_PATH = "img/no_image.jpg";

registerLocale('custom-ja', customJa);

const CameraChange = ({ fetchCameraChange, fetchGetGaugeData, submitCameraEdit, submitCameraDelete, submitAddPhotoAuto, submitAddPhotoManual, submitRemoveGaugeManual, submitUpdateGaugeManual, searchImage, cameraData = {}, dataCount, loading, error }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const company_id = queryParams.get('company_id');
    const [disused_flg, setDisUsedFlg] = useState(0);
    const [image_source, setImage] = useState(0);
    const [api_facility_id, setApiFacilityId] = useState('');
    const [camera_name, setCameraName] = useState('');
    const [facility_list, setFacilityList] = useState([]);
    const [facility_id, setFacilityId] = useState(0);
    const [display_name, setDisplayName] = useState('');
    const [display_name_id, setDisplayNameId] = useState('');
    const [battery_percent, setBattery] = useState('');
    const [firmware_version, setVersion] = useState('');
    const [first_use_day, setDay] = useState('');
    const [is_delete, setIsDelete] = useState(0);
    const [gauge_list, setGaugeList] = useState([]);
    const [currentMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [actionType, setActionType] = useState("");
    const [selectedGaugeIndex, setSelectedGaugeIndex] = useState(0);
    const [company_code, setCompanyCode] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const handleBackClick = () => {
        const page = queryParams.get('page');
        navigate(`/CameraGaugeMaster?page=${page}`);
    };

    const handleOpenPopup = (index) => {
        setSelectedGaugeIndex(index);
        setShowPopup(true);
        document.body.style.overflow = 'hidden';
    };

    const handleClosePopup = (index) => {
        setShowPopup(false);
        document.body.style.overflow = 'unset';
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsDataLoaded(false); 
            await fetchCameraChange(id, company_id); 
            setIsDataLoaded(true); 
        };
        fetchData();
    }, [id, company_id, fetchCameraChange]);
    
    useEffect(() => {
        setDisUsedFlg(cameraData.disused_flg || 0);
        setImage(cameraData.image_source || 0);
        setApiFacilityId(cameraData.api_facility_id || '');
        setCameraName(cameraData.camera_name || '');
        setFacilityList(cameraData.facility_list || []);
        setFacilityId(cameraData.facility_id || 0);
        setDisplayName(cameraData.display_name || '');
        setBattery(cameraData.battery_percent || '');
        setDay(cameraData.first_year_month_day || '');
        setVersion(cameraData.firmware_version || '');
        setIsDelete(cameraData.is_delete || 0);
        setGaugeList(cameraData.gauge_list || []);
        setDisplayNameId(cameraData.display_name_id || '');
        setCompanyCode(cameraData.company_code || '')
    }, [cameraData]);

    const highlightWeekends = (date) => {
        const day = date.getDay();
        return day === 0 ? "highlight-sunday" : day === 6 ? "highlight-saturday" : "";
    };

    const handleDateChange = (date) => {
        const formattedDate = format(new Date(date), "yyyy/MM/dd");
        setDay(formattedDate);
    };

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked ? 1 : 0;
        setDisUsedFlg(checked);
    };

    const handleDeviceSelect = (event) => {
        setFacilityId(event.target.value);
    };

    const handleRadioChange = (event) => {
        setImage(Number(event.target.value));
    }

    const handleDelete = async () => {
        setActionType("delete");
        setModalMessage(is_delete ? message.CANCELLED_CONFIRM_MSG : message.DELETED_CONFIRM_MSG);
        setIsConfirmationModal(true);
        setIsModalOpen(true);
    };

    const handleSave = async () => {
        setActionType("edit");
        if (facility_id === 0) { //&& !disused){
            setModalMessage("施設名を選択してください。");
            setIsModalOpen(true);
            return false;
        }
        if (facility_id !== cameraData.facility_id) {
            setModalMessage(message.FACILITY_CHANGE_CONFIRM_MSG);
            setIsConfirmationModal(true);
            setIsModalOpen(true);
        }
        else {
            setModalMessage("更新しますか？");
            setIsConfirmationModal(true);
            setIsModalOpen(true);
        }
    };

    const handleConfirm = () => {
        switch (actionType) {
            case "edit":
                handleConfirmSave();
                break;
            case "delete":
                handleConfirmDelete();
                break;
            default:
                break;
        }
    };

    const handleConfirmSave = async () => {
        setIsModalOpen(false);
        try {
            const response = await submitCameraEdit(
                company_id,
                id,
                disused_flg,
                image_source,
                facility_id,
                display_name,
                display_name_id,
                first_use_day
            );

            const message = response;
            setModalMessage(message);
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        } catch (error) {
            const errorMessage = error.message;
            setModalMessage(errorMessage);
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        }
    };

    const handleConfirmDelete = async () => {
        setIsModalOpen(false);
        try {
            const response = await submitCameraDelete(
                company_id,
                id,
                is_delete ? 0 : 1,
                display_name_id
            );
            const message = response?.message || (is_delete ? '取消しました。' : '削除しました。');
            setModalMessage(message);
            setIsConfirmationModal(false);
            setIsModalOpen(true);
            // handleBackClick();
        } catch (error) {
            const errorMessage = error.message;
            setModalMessage(errorMessage);
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        }
    };

    return (
        <div id='Main' className='MainMail'  >

            <div id='Contents' className='ContentsMail' >
                
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            if (modalMessage === "削除しました。" ||modalMessage === "取消しました。" || modalMessage === "変更しました。" ) {
                                navigate(-1);
                            }
                        }} 
                        message={modalMessage}
                        onConfirm={handleConfirm}
                        showCancelButton={isConfirmationModal}
                    />
                )}

                {showPopup &&
                    gauge_list.length > 0 &&
                    selectedGaugeIndex >= 0 &&
                    selectedGaugeIndex < gauge_list.length && (
                        <GaugeEdit
                            onClose={handleClosePopup}
                            message={currentMessage}
                            getGaugeData={fetchGetGaugeData}
                            searchImage={searchImage}
                            submitAddPhotoAuto={submitAddPhotoAuto}
                            submitAddPhotoManual={submitAddPhotoManual}
                            submitRemoveGaugeManual={submitRemoveGaugeManual}
                            submitUpdateGaugeManual={submitUpdateGaugeManual}
                            gauge_id={gauge_list[selectedGaugeIndex]?.gauge_id || ''}
                            device_id={gauge_list[selectedGaugeIndex]?.device_id || ''}
                            company_id={company_id}
                            company_code={company_code}
                            facility_id={facility_id}
                        />
                )}

                {!isDataLoaded ? (
                    <div className="loading-container">
                        <div className="overlay active">
                            <div className="spinner active">
                                <img id="waiting-img" src="img/loader.gif" alt="Loading..." />
                            </div>
                        </div>
                    </div>
                ) : (

                    <div id="device-info-display">
                        <div className="display-main">
                            <div className="lay-master-set">
                                <div className="lay-master-set-wrap">
                                    <div className="lay-master-set__title">
                                        <span>カメラ編集</span>
                                    </div>

                                    <div className="lay-master-set__form">
                                        <div className="device-id-container">
                                            デバイスID：  {id}
                                        </div>
                                    </div>

                                    <div id="other-settings-display" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className="setting-column" style={{ marginLeft: '20px' }} >
                                            <div style={{ marginBottom: "20px" }}>
                                                <input type="checkbox" className="checkbox-input " 
                                                    style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} 
                                                    checked={disused_flg === 1} 
                                                    onChange={handleCheckboxChange} 
                                                    disabled={is_delete === 1} />
                                                    カメラを不使用にする
                                            </div>

                                            <div className="lay-master-set__form">
                                                <div className="device-id-content-head">
                                                    表示画像選択
                                                </div>
                                                <div className="device-id-camera">
                                                    <div className="camera-options-container">
                                                        <div className="camera-options">
                                                            <label className="camera-option">
                                                                <input type="radio" id="cam_img_disp" 
                                                                    name="image_source" value="1" 
                                                                    checked={image_source === 1} 
                                                                    onChange={handleRadioChange} 
                                                                    disabled={is_delete === 1} />
                                                                <span>カメラ画像</span>
                                                            </label>
                                                            <label className="camera-option">
                                                                <input type="radio" id="gau_img_disp" 
                                                                name="image_source" value="0" 
                                                                checked={image_source === 0} 
                                                                onChange={handleRadioChange} 
                                                                disabled={is_delete === 1} />
                                                                <span>計器画像</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="lay-master-set__form">
                                                <div className="device-id-content-head">
                                                    API施設ID
                                                </div>
                                                <div className="device-id-content-api">
                                                    {api_facility_id}
                                                </div>
                                            </div>

                                            <div className="lay-master-set__form">
                                                <div className="device-id-content-head">
                                                    カメラ名
                                                </div>
                                                <div className="device-id-content-api">
                                                    {camera_name}
                                                </div>
                                            </div>

                                            <div className="lay-master-set__form" style={{ marginBottom: "20px" }}>
                                                <div className="device-id-content-option">
                                                    施設名
                                                </div>
                                                <div className="mod-form__text">
                                                    <select id="facility_list" 
                                                        name="facility_list" value={facility_id || 0} 
                                                        onChange={handleDeviceSelect} disabled={is_delete === 1} >
                                                        <option value="0">選択してください</option>
                                                            {facility_list.map((facility) => (
                                                                <option key={facility.facility_id} 
                                                                    value={facility.facility_id}>{facility.facility_name_display}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="lay-master-set__form" style={{ marginBottom: "15px" }}>
                                                <div className="device-id-content-option">
                                                    画面表示名
                                                </div>
                                                <div className="mod-form__text">
                                                    <textarea type="text" id="display_name" 
                                                        name="display_name" value={display_name || ''} 
                                                        onChange={(e) => setDisplayName(e.target.value.toString())} 
                                                        disabled={is_delete === 1} 
                                                    />
                                                </div>
                                            </div>

                                            <div className="lay-master-set__form">
                                                <div className="device-id-content-head">
                                                    バッテリー残量
                                                </div>
                                                <div className="device-id-content-api">
                                                    {battery_percent || ""}
                                                </div>
                                            </div>

                                            <div className="lay-master-set__form">
                                                <div className="device-id-content-head">
                                                    ファームバージョン
                                                </div>
                                                <div className="device-id-content-api">
                                                    {firmware_version || ""}
                                                </div>
                                            </div>

                                            <div className="lay-master-set__form" style={{ marginBottom: "15px" }}>
                                                <div className="device-id-content-head">使用開始月</div>
                                                <DatePicker
                                                    selected={first_use_day}
                                                    onChange={handleDateChange}
                                                    dayClassName={highlightWeekends}
                                                    style={{ backgroundColor: 'white', padding: '8px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                                                    locale="custom-ja"
                                                    dateFormat="yyyy/MM/dd"
                                                    className="custom-datepicker"
                                                    calendarStartDay={1}
                                                    disabled={is_delete === 1}
                                                />
                                            </div>

                                            <div style={{ width: '50%', marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                                                <div className='add-btn'>
                                                    <button type="button" className="mod-btn__roundborder add" onClick={handleSave} disabled={is_delete === 1} >
                                                        更新
                                                    </button>
                                                </div>

                                                <div className='back-btn'>
                                                    <button type="button" className={"mod-btn__admindelete add"} onClick={handleDelete}>
                                                        {is_delete === 1 ? '取消' : '削除'}
                                                    </button>
                                                </div>

                                                <div className='back-btn'>
                                                    <button type="button" className="mod-btn__adminback back" onClick={handleBackClick}>
                                                        戻る
                                                    </button>
                                                </div>
                                            </div>
                                        
                                        </div>

                                        <div className="setting-column table_scroll">
                                            <div className="notification-container">
                                                <div className="notification-header">計器一覧</div>
                                            </div>
                                            {gauge_list.length > 0 ? (
                                                <ul className="notification-list">
                                                    {gauge_list.map((gauge, index) => (
                                                        <li key={index} className="notification-item">
                                                            <img
                                                                src={gauge.image_source && gauge.image_source.trim() ? gauge.image_source : NOT_FOUND_IMG_PATH}
                                                                    className="img_small"
                                                                    alt="Display"
                                                                    onError={(e) => {
                                                                    e.target.onerror = null; // 無限の onError コールバックを防止する
                                                                    e.target.src = NOT_FOUND_IMG_PATH;
                                                                }}
                                                            />

                                                            <div className="text-block">
                                                                <div className="gauge-label">
                                                                    <span>【計器表示名】：</span>
                                                                    <span className="gauge-name" >{gauge.gauge_name}</span>
                                                                </div>

                                                                <div className="gauge-label">
                                                                    <span>【画面表示名】：</span>
                                                                    <span className="gauge-name" >{gauge.gauge_display_name}</span>
                                                                </div>

                                                                <div className="button-container">

                                                                    {!gauge.is_active ? (
                                                                        <button
                                                                            type="button"
                                                                            className="mod-btn__camerachange"
                                                                            // onClick={() => handleStopGauge(gauge)}
                                                                        >
                                                                            停止
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className={"mod-btn__cameraelete ? 'disabled' : ''"}
                                                                            onClick={() => handleOpenPopup(index)}
                                                                            disabled={is_delete}
                                                                        >
                                                                            編集
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <div className="notification-message">表示データが存在しません。</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

CameraChange.propTypes = {
    cameraData: PropTypes.object.isRequired,
    dataCount: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCameraChange: PropTypes.func.isRequired,
    fetchGetGaugeData: PropTypes.func.isRequired,
    submitCameraDelete: PropTypes.func.isRequired,
    submitCameraEdit: PropTypes.func.isRequired,
    submitAddPhotoAuto: PropTypes.func.isRequired,
    submitAddPhotoManual: PropTypes.func.isRequired,
    submitRemoveGaugeManual: PropTypes.func.isRequired,
    submitUpdateGaugeManual: PropTypes.func.isRequired,
    searchImage: PropTypes.func.isRequired,
};

export default React.memo(CameraChange);
