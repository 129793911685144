import React, { useState, useEffect,useCallback } from 'react';
import PropTypes from 'prop-types';
import {  useLocation,useNavigate } from 'react-router-dom';
import './MailList.css';
import Modal from '../Modal/Modal';

//メール一覧を表示する
const MailList = ({ mail = [], loading, error, fetchMail,alldatanumber, is_null }) => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const [mailList, seMailList] = useState(['']);
    const itemsPerPage = 20;
    // const [pageindex] = useState(1);    
    const [pageindex] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage] = useState('');
    // const [modalMessage, setModalMessage] = useState('');
    const [search, setSearch] = useState({ company_code: '', company_name: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isLoading, setisLoading] = useState(true);

    //ページインデックスが変更されたときにメールリストをロードする
    useEffect(() => {
        setisLoading(false);
        const query = new URLSearchParams(location.search);
        const page = parseInt(query.get('page'), 10) || 1;  
        setCurrentPage(page);
        
        setSearch({ company_code: '', company_name: '' });
        fetchMail(page, itemsPerPage)
            .finally(() => setisLoading(true));
    }, [fetchMail,itemsPerPage,location]);
    
    //新しいメールリストがある場合に mailList を更新する
    useEffect(() => {
        if (mail) {
            seMailList(mail);
        }
    }, [mail]);
    
    //検索値の変更を処理します
    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    //フォームの送信時に検索リクエストを送信する
    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();
        fetchMail(pageindex,itemsPerPage,search.company_code,search.company_name);
    };
    
    //会社情報編集ページに移動します
    const handleCompanyEdit = useCallback((company_id) => {
        navigate(`/MailEdit?company_id=${company_id}&page=${currentPage}`);
    }, [navigate, currentPage]);
    
    //ページネーション
    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                        <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                            <span>{page}</span>
                        </div>
                    </li>
                    
                    ))}
                </ul>
            </div>
        );
    };

    const handMenuClick = (page) => {
        if(page === currentPage) {
            fetchMail(page, itemsPerPage, search.company_code, search.company_name);
        }
        navigate(`?page=${page}`);
        setCurrentPage(page);
    }

    //ページの変更を処理します
    const handlePageChange = (page) => {
        // navigate(`?page=${page}`);
        // setCurrentPage(page);
        // fetchMail(page, itemsPerPage, search.company_code, search.company_name);
        handMenuClick(page);
    };

    return (
        <div id='Main'className='MainMail' >
           <div id='Contents'className='ContentsMail'>
           {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage} />
                )}

                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メール一覧</span>
                                </div>

                                <form onSubmit={handleSearchSubmit}style={{ marginTop: '20px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>

                                            </li>
                                           
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame"style={{ marginTop: '15px' }}>
                                    <div className="lay-master__table">
                                        <form id="MailList">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-mail-cot1">組織ID</th>
                                                        <th className="col-mail-cot2">組織名</th>
                                                        <th className="col-mail-cot3">メール TO</th>
                                                        <th className="col-mail-cot4">メール CC</th>
                                                        <th className="col-mail-cot5">メール BCC</th>
                                                        <th className="col-mail-cot6">編集</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    {mailList.length > 0 && isLoading === true && is_null === false ? (
                                                        mailList.map((val, index) => (
                                                            <tr key={index} className="lay-tr">
                                                                <td className="col-mail-cot1" style={{ textAlign: 'left' }} >{val.company_code}</td>
                                                                <td className="col-mail-cot2" style={{ textAlign: 'left' }} >{val.company_name}</td>
                                                                <td className="col-mail-cot3" style={{ textAlign: 'left' }} >{val.mail_to ? val.mail_to.split(',').map((email, idx) => (
                                                                    email.trim() ? <div key={idx}>{email.trim()}</div> : <div key={idx}>&nbsp;</div>)) : <div>&nbsp;</div>}</td>
                                                                <td className="col-mail-cot4" style={{ textAlign: 'left' }} > {val.mail_cc ? val.mail_cc.split(',').map((email, idx) => (
                                                                    email.trim() ? <div key={idx}>{email.trim()}</div> : <div key={idx}>&nbsp;</div>)) : <div>&nbsp;</div>}</td>
                                                                <td className="col-mail-cot5" style={{ textAlign: 'left' }} > {val.mail_bcc ? val.mail_bcc.split(',').map((email, idx) => (
                                                                    email.trim() ? <div key={idx}>{email.trim()}</div> : <div key={idx}>&nbsp;</div>)) : <div>&nbsp;</div>}</td>
                                                                <td className="col-mail-cot6">
                                                                    <button type="button" id={`facility_info_edit_${index}`} className="mod-btn__companylist" onClick={() => handleCompanyEdit(val.company_id)}
                                                                    >編集
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6" style={{ textAlign: "center", display: isLoading && is_null ? '' : 'none'  }}>
                                                                <div className="mod-no-data">表示データが存在しません。</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </form>
                                        {totalPages > 1 && isLoading && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )} 

                                        {!isLoading && (
                                            <div className="loading-container">
                                                <div className="overlay active">
                                                    <div className="spinner active">
                                                        <img id="waiting-img" src="img/loader.gif" alt="Loading..." />
                                                    </div>
                                                </div>
                                            </div>
                                        )} 
                                                                               
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

//小道具のデータ型を確認する
MailList.propTypes = {
    mail: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchMail: PropTypes.func.isRequired
};

export default React.memo(MailList);