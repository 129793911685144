import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";
import {utf8ToBase64} from "../helpers/validation";

//データダウンロードリクエストを開始します
export const fetchFacilityRequest = () => ({
    type: ActionTypes.FETCH_FACILITY_REQUEST,
});
//データが正常にダウンロードされたときに処理される
export const fetchFacilitySuccess = (facilities) => ({
    type: ActionTypes.FETCH_FACILITY_SUCCESS,
    payload: facilities,
});
//データのダウンロードが失敗した場合の処理
export const fetchFacilityFailure = (error) => ({
    type: ActionTypes.FETCH_FACILITY_FAILURE,
    payload: error,
});


//この関数はデータをロードし、パラメーター フィルターを受け取り、ページ分割します。
export const fetchFacility = (pageindex,itemsPerPage,company_code,company_name,facility_id,facility_name)=> {
    return async (dispatch) => {
        dispatch(fetchFacilityRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                const maxLength = 255;
                if (company_code) {
                    const trimmed_company_code = company_code.length > maxLength ? company_code.slice(0, maxLength) : company_code;
                    const encoded_company_code = utf8ToBase64(trimmed_company_code);
                    strFilter += `&company_code=${encodeURIComponent(encoded_company_code)}`;
                }
                if (company_name) {
                    const trimmed_company_name = company_name.length > maxLength ? company_name.slice(0, maxLength) : company_name;
                    const encoded_company_name = utf8ToBase64(trimmed_company_name);
                    strFilter += `&company_name=${encodeURIComponent(encoded_company_name)}`;
                }
                if (facility_id) {
                    const trimmed_facility_id = facility_id.length > maxLength ? facility_id.slice(0, maxLength) : facility_id;
                    const encoded_facility_id = utf8ToBase64(trimmed_facility_id);
                    strFilter += `&facility_id=${encodeURIComponent(encoded_facility_id)}`;
                }
                if (facility_name) {
                    const trimmed_facility_name = facility_name.length > maxLength ? facility_name.slice(0, maxLength) : facility_name;
                    const encoded_facility_name = utf8ToBase64(trimmed_facility_name);
                    strFilter += `&facility_name=${encodeURIComponent(encoded_facility_name)}`;
                }
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + `path=facility&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}` + strFilter,                     {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
               
                

                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchFacilitySuccess(data));
            }
            else {
                const data = {"data_list": [{ 'company_id': '1','company_name': 'ハカルプラス', 'facility_id': '59', 'facility_name': '施設１', 'create_date': '2024/11/08','is_delete':1},
                
                {  'company_id': '1','company_name': 'ハカルプラス', 'facility_id': '58', 'facility_name': 'キュービクル', 'create_date': '2024/09/23','is_delete' : 1},
                {  'company_id': '1','company_name': 'ハカルプラス', 'facility_id': '57', 'facility_name': '施設１', 'create_date': '2024/08/26', 'is_delete':0 }
                ], "all_data_count": 5};
                dispatch(fetchFacilitySuccess(data));
            }


        } catch (error) {
            dispatch(fetchFacilityFailure(error.message));
        }
    };
};
