import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  deletedLog: [], 
  logMessages: {}, 
  error: null,
  is_null: false,
};

const deletedListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DELETEDLOGEDIT_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_DELETEDLOGEDIT_SUCCESS:
      return { ...state, loading: false, deletedLog: action.payload, is_null: action.payload.lenght === 0 };
    case ActionTypes.FETCH_DELETEDLOGEDIT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.FETCH_DELETEDLOGEDITMSG_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_DELETEDLOGEDITMSG_SUCCESS:
      return {...state,loading: false,logMessages: action.payload,};
    case ActionTypes.FETCH_DELETEDLOGEDITMSG_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.FETCH_DELETEDLOGEDITDETAIL_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_DELETEDLOGEDITDETAIL_SUCCESS:
      return {...state,loading: false,logMessages: action.payload,};
    case ActionTypes.FETCH_DELETEDLOGEDITDETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default deletedListReducer;

