import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './AdminEdit.css';
import Modal from '../Modal/Modal';
import { checkInput } from '../../helpers/validation';
import CompanySearch from '../CompanySearch/CompanySearch';
import { format } from 'date-fns';
import message from "../../constants/message";

const formatMessage = (template, ...values) => {
    return values.reduce((formatted, value) => formatted.replace('%s', value), template);
}

const AdminEdit = ({ fetchAdminEdit, submitAdminEdit, deleteAdmin, admindata = {}, loading, error }) => {
    const navigate = useNavigate();
    const [login_id, setLoginId] = useState('');
    const [admin_name, setAdmin_name] = useState('');
    const [lilzgauge_api_token, setLilzgauge_api_token] = useState('');
    const [lilz_gauge_token_date, setLilzgauge_api_token_date] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [company_id, setCompany_id] = useState('');
    const [access_token, setAccessToken] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const admin_id = queryParams.get('admin_id');
    // const [isFetching, setIsFetching] = useState(false);
    const sessionAdminName = localStorage.getItem('admin_name');
    const isDisabled = sessionAdminName === admin_name;
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [email, setEmail] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    // const [userType, setUserType] = useState('admin');
    const [isDataLoaded, setIsDataLoaded] = useState(false);


    const createAccessToken = () => {
        const randomBytes = new Uint8Array(32);
        window.crypto.getRandomValues(randomBytes);
        const tokenString = Array.from(randomBytes, byte => byte.toString(16).padStart(2, '0')).join('');
        setAccessToken(tokenString);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsDataLoaded(false); 
            await fetchAdminEdit(admin_id); 
            setIsDataLoaded(true); 
        };
        fetchData();
    }, [admin_id, fetchAdminEdit]);

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        setIs2FAEnabled(checked);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        console.log("admindata Data Updated:", admindata);
        if ( Object.keys(admindata).length > 0) {
            setLoginId(admindata.login_id|| '');
            setAdmin_name(admindata.admin_name|| '');
            setLilzgauge_api_token(admindata.lilz_gauge_token|| '');
            setLilzgauge_api_token_date(admindata.lilz_gauge_token_date|| '');
            setAccessToken(admindata.access_token|| '');
            setCompany_name(admindata.company_name|| '');
            setCompany_id(admindata.company_id|| '');
            setIs2FAEnabled(admindata.otp_enable|| '');
            setEmail(admindata.mail_address|| '');
        }
    }, [admindata]);

    useEffect(() => {
        if (lilzgauge_api_token) {
            const today = new Date();
            setLilzgauge_api_token_date(format(today, 'yyyy/MM/dd'));
        }
        else {
            setLilzgauge_api_token_date('');
        }
    }, [lilzgauge_api_token]);


    const handleBackClick = () => {
        const page = queryParams.get('page');  
        navigate(`/AdminMaster?page=${page}`);
    };

    const handlePWChange = () => {
        navigate(`/passwordChangeDisplay?userType=admin&loginId=${admin_id}`);
    };
    
    
    const validateInput = () => {
        const errors = {};
        var errmessage = checkInput(login_id, 5, 16, 'ログインID');
        if (errmessage) {

            errors.loginid = errmessage;
        }
        if (admin_name.length > 255) {
            errors.admin_name = formatMessage(message.MAX_LENGTH_MSG_TT, 'ユーザ名',255);
        }
        if (is2FAEnabled && !email.trim()) {
            errors.is2FAEnabled = 'メールアドレスを入力してください。';
        }
        else if (is2FAEnabled && !isEmailValid(email)) {

            errors.is2FAEnabled = 'メールアドレスが正しくありません。';
        }
        else if (is2FAEnabled && email.length > 50) {
            errors.is2FAEnabled = formatMessage(message.MAX_LENGTH_MSG_TT, 'メールアドレス', 50);
        }        
        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSaveAdmin = async () => {
        if (!validateInput()) {
            return;
        }

        try {
            let strlilzgauge_api_token = lilzgauge_api_token;
            let strlilzgauge_api_token_date = lilz_gauge_token_date;
            if (lilzgauge_api_token) {
                if (!lilzgauge_api_token.trim()) {
                    strlilzgauge_api_token = '';
                    strlilzgauge_api_token_date = '';
                }
            }

            const payload = {
                "admin_id": admin_id,
                "login_id": login_id,
                "admin_name": admin_name,
                "lilz_gauge_token": strlilzgauge_api_token,
                "lilz_gauge_token_date": strlilzgauge_api_token_date,
                "access_token": access_token,
                "company_id": company_id,
                "otp_enable": is2FAEnabled ? 1 : 0,
                "mail_address": email,
            };
            const response = await submitAdminEdit(payload);
            setModalMessage(response?.message || "変更しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage("登録に失敗しました。");
            setIsModalOpen(true);
        }
    };

    const handleDeleteAdmin = () => {

        setModalMessage('削除しますか？');
        setIsConfirmationModal(true);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        setIsModalOpen(false);
        try {
            const response = await deleteAdmin(admin_id);
            const message = response?.message || '削除しました。'; 
            setModalMessage(message); 
            setIsModalOpen(true);
            setIsConfirmationModal(false);
        } catch (error) {
            setModalMessage(error.message || 'エラーが発生しました。再試行してください。');
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        } 
    };

    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleSelect = (item, id) => {
        setCompany_name(item);
        setCompany_id(id);
    };

    // Validate email format
    const isEmailValid = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <div id='Main' className='MainEdit' >
            <div id='Contents' className='ContentEdit'>
                {/* メッセジー画面 */}
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            if (modalMessage === "削除しました。" || modalMessage === "変更しました。") {
                                navigate(-1);
                            }
                        }}
                        message={modalMessage}
                        onConfirm={handleConfirmDelete}
                        showCancelButton={isConfirmationModal}
                    />
                )}
                
                {/* 組織検索画面 */}
                {showPopup && (<CompanySearch onClose={handleClosePopup} onSelect={handleSelect} />)}
                {/* 組織一覧画面 */}
                {!isDataLoaded ? (
                    <div className="loading-container">
                        <div className="overlay active">
                            <div className="spinner active">
                                <img id="waiting-img" src="img/loader.gif" alt="Loading..." />
                            </div>
                        </div>
                    </div>
                ) : (

                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>管理者編集</span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="AdminNew">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    {/* ボタン */}
                                                    <div style={{ width: '70%', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminedit morelong"
                                                                    onClick={handlePWChange}
                                                                >
                                                                    パスワード編集
                                                                </button>
                                                            </div>
                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleSaveAdmin}
                                                                >
                                                                    更新
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className={"mod-btn__admindelete add isDisabled ? 'disabled' : ''"}
                                                                    onClick={handleDeleteAdmin}
                                                                    disabled={isDisabled}
                                                                >
                                                                    削除
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 設定項目 */}

                                                    <div className="mod-form__set" style={{ marginTop: '40px' }} >
                                                            <dl>
                                                                <dt>ログインID</dt>
                                                                <dd style={{ width: '60%' }}>
                                                                    <div className="mod-form__text">
                                                                        <input
                                                                            type="text"
                                                                            id="login_id"
                                                                            name="login_id"
                                                                            value={ login_id}
                                                                            onChange={(e) => setLoginId(e.target.value.toLowerCase())} 
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="error_msg">
                                                                            {errorMessages.loginid && <span style={{ color: 'red' }}>{errorMessages.loginid}</span>}
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                    
                                                            <dl style={{ marginTop: '10px' }}>
                                                                <dt>ユーザ名</dt>
                                                                <dd style={{ width: '60%' }}>
                                                                    <div className="mod-form__text">
                                                                        <input
                                                                            type="text"
                                                                            id="admin_name"
                                                                            name="admin_name"
                                                                            value={ admin_name}
                                                                            onChange={(e) => setAdmin_name(e.target.value)}
                                                                            autoComplete="off"
                                                                        />
                                                                        <div className="error_msg">
                                                                            {errorMessages.admin_name && <span style={{ color: 'red' }}>{errorMessages.admin_name}</span>}
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>

                                                            <dl style={{ marginTop: '10px' }}>
                                                                <dt className='textarea'>APIトークン</dt>
                                                                <dd style={{ width: '60%' }}>
                                                                    <div className="mod-form__text">
                                                                        <textarea
                                                                            type="text"
                                                                            id="lilzgauge_api_token"
                                                                            name="lilzgauge_api_token"
                                                                            value={ lilzgauge_api_token || ""}
                                                                            onChange={(e) => setLilzgauge_api_token(e.target.value.toString())}
                                                                            autoComplete= "off"
                                                                        />
                                                                    </div>
                                                                </dd>
                                                            </dl>

                                                            <dl style={{ marginTop: '10px' }}>
                                                                <dt>APIトークン登録日</dt>
                                                                <dd style={{ width: '60%' }}>
                                                                    <div className="mod-form__text">
                                                                        <input
                                                                            className='readonlyCls'
                                                                            type="text"
                                                                            id="lilz_gauge_token_date"
                                                                            name="lilz_gauge_token_date"
                                                                            value={ lilz_gauge_token_date }
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </dd>
                                                            </dl>

                                                            <dl style={{ marginTop: '10px' }}>
                                                                <dt>アクセストークン</dt>
                                                                <dd style={{ width: '60%' }}>
                                                                    <div className="mod-form__text">
                                                                        <input
                                                                            className='readonlyCls'
                                                                            type="text"
                                                                            id="access_token"
                                                                            name="access_token"
                                                                            value={ access_token }
                                                                            onChange={(e) => setAccessToken(e.target.value)}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </dd>
                                                                <button type="button" className="mod-btn__roundborder add" style={{ marginLeft: '10px' }} onClick={createAccessToken}>
                                                                    生成
                                                                </button>
                                                            </dl>
                                                        
                                                            <dl style={{ marginTop: '10px' }}>
                                                            <dt>管理組織</dt>
                                                                <dd style={{ width: '60%' }}>
                                                                    <div className="mod-form__text">
                                                                        <input
                                                                            className='readonlyCls'
                                                                            type="text"
                                                                            id="company_name"
                                                                            name="company_name"
                                                                            value={ company_name }
                                                                            onChange={(e) => setCompany_name(e.target.value)}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </dd>
                                                                <button type="button" className="mod-btn__roundborder add" style={{ marginLeft: '10px' }} onClick={handleOpenPopup} >
                                                                    組織検索
                                                                </button>
                                                            </dl>
                                                        
                                                            <dl style={{ marginTop: '10px' }}>
                                                            <dt className="double">二段階認証</dt>
                                                                <dd style={{ width: '60%' }}>
                                                                    <label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                                                        <input type="checkbox" className="checkbox-input" 
                                                                            style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} 
                                                                            checked={is2FAEnabled} 
                                                                            onChange={handleCheckboxChange} />
                                                                        <span style={{ marginRight: '10px' }}></span>
                                                                        二段階認証を使用する
                                                                    </label>

                                                                    <div className="mod-form__company">
                                                                        <input
                                                                            type="text"
                                                                            name="mail_2fa"
                                                                            value={email || ""}
                                                                            onChange={handleEmailChange}
                                                                            readOnly={!is2FAEnabled}
                                                                            style={{ background: is2FAEnabled ? '#fff' : 'lightgray' }}
                                                                            autoComplete="off" 
                                                                        />
                                                                        <div className="error_msg">
                                                                            {errorMessages.is2FAEnabled && <span style={{ color: 'red' }}>{errorMessages.is2FAEnabled}</span>}
                                                                        </div>
                                                                    </div>
                                                                </dd>
                                                            </dl>
                                                        
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
};

AdminEdit.propTypes = {
    admindata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchAdminEdit: PropTypes.func.isRequired,
    deleteAdmin: PropTypes.func.isRequired,
    submitAdminEdit: PropTypes.func.isRequired
};

export default React.memo(AdminEdit);