import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  maintenance: [],
  error: null,
  alldatanumber: 0,
  is_null: false
};

const maintenancelistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_MAINTENANCE_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_MAINTENANCE_SUCCESS:
      return { ...state, loading: false,maintenance: action.payload.data_list,error: null, alldatanumber: action.payload.all_data_count, is_null: action.payload.data_list.length === 0 };
    case ActionTypes.FETCH_MAINTENANCE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.FETCH_MAINTENANCEDETAIL_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_MAINTENANCEDETAIL_SUCCESS:
      return { ...state, loading: false, detail: action.payload };
    case ActionTypes.FETCH_MAINTENANCEDETAIL_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default maintenancelistReducer;
