import React, { useState } from 'react';
import PropTypes from 'prop-types';

//メッセージの編集と保存を許可します
function MailSearch({ onClose, message, onSave }) {
    const [inputMessage, setInputMessage] = React.useState(message);
    // const [screenMessage, setScreenMessage] = useState("");
    const [errors, setErrors] = useState({});
    // 入力値の変更を処理します
    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    // フォーム送信時のハンドル
    const handleSubmit = (e) => {
        e.preventDefault();
        // 保存する前にエラーを確認する。
        if (!inputMessage.trim()) {
            setErrors({ screenMessage: 'メッセージを入力してください。' });
            return;
        } else if (inputMessage.trim().length > 100) {
            setErrors({ screenMessage: 'メッセージを100文字以下で入力してください。' });
            return;
        }
    
        onSave(inputMessage);
        onClose();
    };

    return (
        <div className="popup-wrapper-company">
            <div className="popup-maintenance">
                <div className="popup-top">
                    <span>メッセージ編集画面</span>
                    {/* <button className="modal-close" onClick={onClose}>&times;</button> */}
                    <span className="modal-close" onClick={onClose} >✖</span>
                </div>
                <div className="popup-content-maintenance">
                    <form onSubmit={handleSubmit}>
                        <div className="lay-search">
                            <div className="lay-search-wrap-company">
                                <div className="lay-master-set__title">
                                    <span>メッセージ編集</span>
                                    <hr style={{ border: "1px solid #ccc", marginTop: "10px" }} />
                                </div>
                                <div className='lay-search-company__form__col3' style={{ marginTop: "30px" }} >
                                    <div className="mod-form-company__set">
                                        <dl>
                                            <dt>メッセージ</dt>
                                            <dd>
                                                <div className='mod-form-mail__text'>
                                                    <input type="text" id="company_name" name="company_name"
                                                        value={inputMessage} 
                                                        onChange={handleInputChange} 
                                                        autoComplete="off" 
                                                        style={{
                                                            border: "1px solid #ccc", 
                                                        }}
                                                    />
                                                    {errors.screenMessage && (
                                                        <div className="error_message" style={{ color: 'red', marginTop: '10px'}}>
                                                            {errors.screenMessage}
                                                        </div>
                                                    )}
                                                </div>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className='lay-search-company__form__col3'>
                                    <ul style={{ display: 'flex', alignItems: 'center', gap: '20px', listStyle: 'none', padding: '0', margin: '0' }}>
                                        <li>
                                            <button type="submit" className="btn_mailsearch">更新</button>
                                        </li>
                                        <li>
                                            <button type="button" onClick={onClose} className="btn_mailsearchbacktest">キャンセル</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

// コンポーネントに渡された props を確認します
MailSearch.propTypes = {
    onClose: PropTypes.func.isRequired,
    message: PropTypes.any.isRequired, 
    onSave: PropTypes.func.isRequired,
};

export default MailSearch;

