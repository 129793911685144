import React from 'react';
import { Navigate } from 'react-router-dom';
import PageNotFound from "../components/error_404"

const PrivateRoute = ({ children , requiredAuthority }) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const authorityClass = parseInt(localStorage.getItem("authorityClass"), 10);

  if(!isAuthenticated){
    return <Navigate to="/login" replace />
  }

  if(requiredAuthority && authorityClass !== requiredAuthority) {
    return <PageNotFound/>
  }

  return children;
  // return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;