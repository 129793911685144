import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    companies: [],
    // managementUnitData: [],
    error: null,
    alldatanumber: 0,
    is_null: false
};

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_COMPANIES_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_COMPANIES_SUCCESS:
            return { ...state, loading: false, companies: action.payload.data_list, alldatanumber: action.payload.all_data_count, is_null: action.payload.data_list.length === 0};
        case ActionTypes.FETCH_COMPANIES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.FETCH_MANAGEMENT_UNIT_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_MANAGEMENT_UNIT_SUCCESS:
            const managementUnitData = action.payload;
            const updatedCompanies = state.companies.map(company => {
                const updatedCompany = managementUnitData.find(item => item.company_id === company.company_id);
                return updatedCompany ? { ...company, ...updatedCompany } : company;
            });

            return { ...state, loading: false, companies: updatedCompanies};
        case ActionTypes.FETCH_MANAGEMENT_UNIT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.REGISTER_COMPANY_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.REGISTER_COMPANY_SUCCESS:
            return { ...state, loading: false, message: action.payload };
        case ActionTypes.REGISTER_COMPANY_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default companyReducer;
