import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import { authHeader } from "../helpers";
import axiosInstance from "../helpers/refreshTokenMiddleware";

//ログイン要求を開始します。
export const requestsendLoginInformation = () => ({
  type: ActionTypes.REQUEST_SEND_LOGIN_INFORMATION
});

//ログインに成功したら、accessToken とfreshToken を localStorage に保存します。
export const sendLoginInformationSuccess = (accessToken, refreshToken, otpEnabled, otpToken = null) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  return {
    type: ActionTypes.RECEIVE_SEND_LOGIN_INFORMATION_SUCCESS,
    payload: { accessToken, refreshToken, otpEnabled, otpToken }
  };
};

//ログイン処理中にエラーが発生した場合の処理​​。
export const sendLoginInformationFailure = error => ({
  type: ActionTypes.RECEIVE_SEND_LOGIN_INFORMATION_FAILURE,
  payload: error,
  error: true
});

//OTP が要求されたときに sessionId と otpToken を保存します。
export const receiveotpEnabled = (otpToken, sessionId) => ({
  type: ActionTypes.RECEIVE_OTP_ENABLED,
  payload: { otpToken, sessionId },
});

//資格情報を送信し、応答を処理します。
export const sendLoginInformation = credentials => async dispatch => {

  dispatch(requestsendLoginInformation());
  try {
    const response = await fetch(BASE_URL + 'path=login-admin', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(credentials),
    });
    const data = await response.json();
    
    if ((!response.ok) || (data.status!== 200)) {
      throw new Error(data.respons.message || 'error');
    }

    if (data.respons && data.respons.otp_enable === 1) {
      dispatch(receiveotpEnabled(data.respons.session_id));
    } else {
      dispatch(sendLoginInformationSuccess(data.respons.access_token, data.respons.refresh_token, false));
      dispatch(fetchUserSuccess({
        admin_name: data.respons.admin_name,
        authorityClass: data.respons.authority_class,
      }));
    }

  } catch (error) {

    dispatch(sendLoginInformationFailure(error.message));
  }
};

//OTP チェック要求を開始します。
export const CheckOtpRequest = () => ({
  type: ActionTypes.CHECK_OTP_REQUEST
});

//OTPチェックが成功したら、accessTokenとrefreshTokenをlocalStorageに更新します。
export const CheckOtpSuccess = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  return {
    type: ActionTypes.CHECK_OTP_SUCCESS,
    payload: { accessToken, refreshToken }
  };
};

//OTP チェックプロセス中にエラーが発生した場合の処理​​。
export const CheckOtpFailure = error => ({
  type: ActionTypes.CHECK_OTP_FAILURE,
  payload: error,
  error: true
});

//OTP チェック要求をサーバーに送信します。
export const CheckOtp = (otpCode, sessionId, loginId, password) => async (dispatch, getState) => {
  dispatch(CheckOtpRequest());
  const payload = JSON.stringify({
    otp: otpCode,
    session_id: sessionId,
    login_id: loginId,
    password: password,
  });

  try {
    const response = await fetch(BASE_URL + 'path=login-admin', {
      method: 'POST',
      headers: authHeader(),
      body: payload,
    });
    const data = await response.json();

    if (data.status === 401) {
      dispatch(CheckOtpFailure(data.respons?.mesage || 'error'));
      dispatch(incrementOtpAttempt());
    } else {
      dispatch(CheckOtpSuccess(data.respons.access_token, data.respons.refresh_token));
      if (data.respons.admin_name) {
        dispatch(fetchUserSuccess({ admin_name: data.respons.admin_name }));
      }
    }
  } catch (error) {
    dispatch(CheckOtpFailure(error.message));
    dispatch(incrementOtpAttempt());
  }
};

//OTP 再送信要求を開始します。
export const resendOtpRequest = () => ({
  type: ActionTypes.RESEND_OTP_REQUEST
});

//OTP再送信が成功した場合の処理​​。
export const resendOtpSuccess = message => ({
  type: ActionTypes.RESEND_OTP_SUCCESS,
  payload: message
});

//OTP再送処理中にエラーが発生した場合の処理​​を行います。
export const resendOtpFailure = error => ({
  type: ActionTypes.RESEND_OTP_FAILURE,
  payload: error,
  error: true
});

export const resendOtp = (loginId, password, sessionId) => async dispatch => {
  dispatch(resendOtpRequest());
  try {
    const response = await fetch(BASE_URL + 'path=login-admin', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({ login_id: loginId, password: password, session_id: sessionId })
    });
    const data = await response.json();
    if (response.ok) {

      dispatch(resendOtpSuccess(data.respons.session_id));

    } else {
      throw new Error(data.message || 'error');
    }
  } catch (error) {
    dispatch(resendOtpFailure(error.message));
  }
};

export const incrementOtpAttempt = () => ({
  type: ActionTypes.INCREMENT_OTP_ATTEMPT,
});

export const refreshAccessTokenRequest = () => ({
  type: ActionTypes.REFRESH_ACCESS_TOKEN_REQUEST
});

export const refreshAccessTokenSuccess = (token) => ({
  type: ActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS,
  payload: token
});

export const refreshAccessTokenFailure = (error) => ({
  type: ActionTypes.REFRESH_ACCESS_TOKEN_FAILURE,
  payload: error,
  error: true
});

export const refreshAccessToken = (refreshToken) => async (dispatch) => {
  dispatch(refreshAccessTokenRequest());

  console.log("Sending refresh token request with:", refreshToken);

  try {
    const response = await fetch(BASE_URL + 'path=refresh-token', {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({ refresh_token: refreshToken })
    });

    const data = await response.json();

    console.log("Received response:", data);

    if (!response.ok) {
      throw new Error(data.message || 'Failed to refresh access token');
    }

    const newAccessToken = data.newAccessToken;

    console.log("New access token received:", newAccessToken);

    dispatch(refreshAccessTokenSuccess(newAccessToken));
    return newAccessToken;
  } catch (error) {
    console.error("Error during token refresh:", error);
    dispatch(refreshAccessTokenFailure(error.message));
    throw error;
  }
};

export const fetchUserSuccess = (user) => ({
  type: ActionTypes.FETCH_USER_SUCCESS,
  payload: user
});

export const fetchUserFailure = (error) => ({
  type: ActionTypes.FETCH_USER_FAILURE,
  payload: error,
  error: true
});

export const fetchUserData = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      dispatch(fetchUserFailure('No access token found in localStorage'));
      return;
    }
    const response = await axiosInstance.get(BASE_URL + 'path=login-admin', {

      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    dispatch(fetchUserSuccess(response.data));
  } catch (error) {
    dispatch(fetchUserFailure(error.message));
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  dispatch({ type: ActionTypes.LOGOUT_USER });
  window.location.href = '/login';
};