import React, { useState, useEffect,useMemo  } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import './FacilityEdit.css';
import CompanySearch from '../CompanySearch/CompanySearch';
import message from "../../constants/message";

const formatMessage = (template, ...values) => {
    return values.reduce((formatted, value) => formatted.replace('%s', value), template);
}

const FacilityEdit = ({ fetchFacilityEdit, submitFacilityEdit, deleteFacility, facilitydata = {}, loading, error }) => {
    const navigate = useNavigate();
    const [facility_name, setFacility_name] = useState('');
    const [facility_id, setFacility_id] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [company_id, setCompany_id] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [isFetching, setIsFetching] = useState(false);
    const [islock, setLock] = useState(0);
    const [errorMessages, setErrorMessages] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const id = queryParams.get('facility_id');
            setFacility_id(id);
    
            if (id) {
                setIsDataLoaded(false);
                try {
                    await fetchFacilityEdit(id);
                } catch (error) {
                    console.error('error:', error);
                } finally {
                    setIsDataLoaded(true);
                }
            }
        };
    
        fetchData();
    }, [queryParams, fetchFacilityEdit]);
    
    
    
    const handleSelect = (name, id) => {
        setCompany_name(name);
        setCompany_id(id); 
    };

    //取得したベース情報をステートに保存します
    useEffect(() => {
        if (facilitydata) {
            setFacility_name(facilitydata.facility_name || ''); 
            setCompany_name(facilitydata.company_name || '');
            setCompany_id(facilitydata.company_id || '');
            setLock(facilitydata.is_delete || 0);
        }
    }, [facilitydata]);

    //前のページに戻るイベントを処理する
    const handleBackClick = () => {
        const page = queryParams.get('page');  
        navigate(`/FacilityMaster?page=${page}`);  
    };

    const validateInput = () => {
        const errors = {};
        // Check for empty input fields

        if (facility_name.length > 255) {
            errors.facility_name = formatMessage(message.MAX_LENGTH_MSG_TT, '施設名', 255);
        }
        
        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };
    
    //編集情報の保存を処理します
    const handleSaveFacility = async () => {
        if (!validateInput()) {
            return;
        }
        try {
            const payload = {
                facility_name: facility_name,
                facility_id: facility_id,
                company_id: company_id,
            };
            const response = await submitFacilityEdit(payload);
            setModalMessage(response?.message || "変更しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage("登録に失敗しました。");
            setIsModalOpen(true);
        }
    };

    //基本の削除または破棄イベントを処理します
    const handleDeleteAction = () => {
        if (islock === 1) {
            setModalMessage('取消しますか？');
        } else {
            setModalMessage('削除しますか？');
        }
        setIsConfirmationModal(true);
        setIsModalOpen(true); 
    };

    //削除またはキャンセルのアクションを確認する
    const handleConfirmAction = async () => {
        setIsModalOpen(false);
        // setIsFetching(true);
        try {
            const is_delete = modalMessage.includes('削除');
            const is_unlock = modalMessage.includes('取消');
            const response = await deleteFacility(facility_id, facility_name, company_id, is_delete ? 1 : 0);
            let message;
            if (is_delete) {
                message = response?.message || '削除しました。'; 
        } else if (is_unlock) {
            message = response?.message || '取消しました。'; 
        }
            setModalMessage(message); 
            setIsModalOpen(true);
            setIsConfirmationModal(false);
        } catch (error) {
            setModalMessage(error.message || 'エラーが発生しました。再試行してください。');
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        }
         finally {
            setIsFetching(false);
        }
    };
    
    //企業検索ポップアップの開閉
    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div id='Main' className='MainEdit'  >
            <div id='Contents'className='ContentEdit'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            if (modalMessage === "削除しました。" || modalMessage === "取消しました。"|| modalMessage === "変更しました。" ) {
                                navigate(-1);
                            }
                        }}                       
                        message={modalMessage} 
                        onConfirm={handleConfirmAction}
                        showCancelButton={isConfirmationModal}
                    />
                )}

                {showPopup && (<CompanySearch onClose={handleClosePopup} onSelect={handleSelect} />)}

                {!isDataLoaded ? (
                    <div className="loading-container">
                        <div className="overlay active">
                            <div className="spinner active">
                                <img id="waiting-img" src="img/loader.gif" alt="Loading..." />
                            </div>
                        </div>
                    </div>
                ) : (

                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>施設マスタ編集 </span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="FacilityEdit">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    <div style={{ width: '73%', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ visibility: 'hidden' }}>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminedit morelong"
                                                                    
                                                                >
                                                                    none
                                                                </button>
                                                            </div>
                                                            
                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleSaveFacility}
                                                                    disabled={islock === 1}
                                                                >
                                                                    更新
                                                                </button>
                                                            </div>
                                                            
                                                            <div className="back-btn">
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__admindelete add"
                                                                    onClick={handleDeleteAction}
                                                                >
                                                                    {islock === 1 ? '取消' : '削除'}
                                                                </button>
                                                            </div>

                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mod-form__set">

                                                        <dl style={{ marginTop: '40px' }}>
                                                            <dt>登録組織</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="company_name"
                                                                        name="company_name"
                                                                        value={ company_name } 
                                                                        onChange={(e) => setCompany_name(e.target.value)}
                                                                        readOnly
                                                                    />
                                                                </div>

                                                            </dd>
                                                            <button type="button" className="mod-btn__roundborder add" style={{ marginLeft: '10px' }} onClick={handleOpenPopup}disabled={islock === 1} >
                                                                組織検索
                                                            </button>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>施設ID</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                <input
                                                                    type="text"
                                                                    className='readonlyCls'
                                                                    id="facility_id"
                                                                    name="facility_id"
                                                                    value={ facility_id }  
                                                                    onChange={(e) => setFacility_id(e.target.value)}
                                                                    readOnly  
                                                                />
                                                                </div>

                                                            </dd>

                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>施設名</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                    
                                                                        type="text"
                                                                        id="facility_name"
                                                                        name="facility_name"
                                                                        value={ facility_name }
                                                                        className={islock === 1 ? 'readonlyCls' : ''}
                                                                        readOnly={islock === 1}
                                                                        onChange={(e) => setFacility_name(e.target.value)}
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.facility_name && <span style={{ color: 'red' }}>{errorMessages.facility_name}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                )}

            </div>
        </div>
    );
};
//小道具のデータ型を確認する
FacilityEdit.propTypes = {
    facilitydata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchFacilityEdit: PropTypes.func.isRequired,
    deleteFacility: PropTypes.func.isRequired,
    submitFacilityEdit: PropTypes.func.isRequired
};

export default React.memo(FacilityEdit);