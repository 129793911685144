import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import purify from "dompurify";
import './login.css';

//ログイン ID、パスワード、OTP
const Login = React.memo( ({ isFetching, otpToken, accessToken, refreshToken, errorMessage,otpEnabled, sendLoginInformation,CheckOtp,otpAttemptCount ,sessionId,resendOtp }) => {
    const [loginId, setLoginId] = useState('');
    const [password, setPassword] = useState('');
    const [idOtpForm, setidOtpForm] = useState(false);
    const [otpCode, setOtpCode] = useState('');
    const [resendNotification, setResendNotification] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [showErrorMessage, setShowErrorMessage] = useState(false); 
    const [isSubmit, setIsSubmit] = useState(false);
    const [isSubmitOtp, setIsSubmitOtp] = useState(false);

    // ログイン画面表示時に localStorage を削除
    useEffect(() => {
      localStorage.removeItem('admin_name'); 
      localStorage.removeItem('authorityClass'); 
    }, []);

    // ログインを処理します
    const handleLogin = (event) => {
      event.preventDefault();
      // 追加送信を許可しません。
      if (isSubmit) return;
        //送信中のステータスを設定します。
        setIsSubmit(true); 
        sendLoginInformation({
          login_id: loginId,
          password: password,
        }).finally(() => {
          setIsSubmit(false); 
      });
    };

    // OTP 送信の処理
    const handleSubmit = (event) => {
      event.preventDefault();
      //  追加送信を許可しません
      if (isSubmitOtp) return;
        setIsSubmitOtp(true); 
        CheckOtp(otpCode, otpToken, loginId, password)
      .finally(() => {
        setIsSubmitOtp(false); 
      });
    };

    // OTP コードを更新します
    const handleOtpChange = (e) => {
      setOtpCode(e.target.value);
    };

    // OTP の再送信を処理します
    const handleResendClick = () => {
      setCountdown(10);
      setResendNotification(false);
      resendOtp(loginId, password, sessionId);
      setModalMessage('認証コードを再送信しました。');
      setModalOpen(true);
    };
    
    // accessToken が存在し、OTP が要求されていない場合は、管理ページに移動します
    useEffect(() => {
      if (accessToken && !otpEnabled) {
        navigate('/AdminMaster');
      }
    }, [accessToken, otpEnabled, navigate]);
    
    // 必要に応じて OTP フォームを表示します
    useEffect(() => {
      if (otpEnabled && otpToken) {
        setidOtpForm(true);
        setShowErrorMessage(false); 
      } else {
        setShowErrorMessage(true);
      }
    }, [otpEnabled, otpToken]);

    // OTP を再送信するまでのカウントダウン時間
    useEffect(() => {
      const interval = setInterval(() => {
        if (otpToken) {
          if (countdown > 0) {
            setCountdown(countdown - 1);
          } else {
            clearInterval(interval);
            setResendNotification(true);
          }
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [countdown, otpCode, otpToken]);

    // 間違った OTP を 3 回以上入力した場合にモーダルを表示します
    useEffect(() => {
      if (otpAttemptCount >= 3) {
        setIsModalOpen(true);  
        setModalMessage("OTPコードが3回以上間違いました。再ログインしてください。");
      }
    }, [otpAttemptCount]); 
  
    
    // useEffect(() => {
    //   if (otpAttemptCount >= 4) {
    //     setIsModalOpen(true);
    //     setModalMessage("OTPコードが3回以上間違いました。再ログインしてください。");
    //     setShowErrorMessage(false);
    //   } else if (otpAttemptCount > 0 && otpAttemptCount < 4) {
    //     setShowErrorMessage(true);
    //   } else {
    //     setShowErrorMessage(false);
    //   }
    // }, [otpAttemptCount]);
    
    // エラーがあれば表示します
    useEffect(() => {
      if (errorMessage && otpAttemptCount < 3) {
        setShowErrorMessage(true);
      } else {
        setShowErrorMessage(false);
      }
    }, [errorMessage, otpAttemptCount]);

    //通知を表示
    const Modal = ({ isOpen, onClose, message }) => {
      if (!isOpen) return null;
      return (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="modal-close" onClick={onClose}>✖</span>
            <p className='modal-text'>{message}</p>
            <hr className="modal-divider" />
            <button className="btn-modal-close" onClick={onClose}>OK</button>
          </div>
        </div>
      );
    };

    // モーダルを閉じてログインページに戻ります
    const closeModal = () => {
      setIsModalOpen(false);
      setidOtpForm(false);
      setLoginId('');
      setPassword('');
      navigate('/login', { replace: true });
      window.location.reload(true);
    };
    
    return  (
      <div className="lay-login">
        <Modal isOpen={isModalOpen} onClose={closeModal} message={modalMessage} />

        {idOtpForm  ? (
          <div className="lay-login__sub">
            <img src="img/logo.png" alt="Logo" className="logo" />
            <div className="login-form">
               {showErrorMessage && errorMessage  && ( <p className="error" dangerouslySetInnerHTML={{ __html: purify.sanitize(errorMessage) }} />)}
        
                <form id="otp-form" method="post" onSubmit={handleSubmit}>

                  <div className="otp-check-container">

                    <div className="otp-check-div">
                        <span id="otp-code-label">認証コード</span>
                    </div>

                    <div className="otp-check-div">
                        <input type="text" id="otp_code" name="otp_code" maxLength="6" autoComplete="off" value={otpCode} onChange={handleOtpChange}/>
                    </div>

                    <div className="otp-check-div">
                        <button type="submit" className="btn btn-primary" disabled={isSubmitOtp} >
                          認証
                        </button>
                    </div>
                  </div>

                </form>

                {resendNotification && (
                  <div id="resend-otp">
                      <span> 認証コードが受信できない場合、
                          <em onClick={handleResendClick} style={{ cursor: 'pointer' }} > こちら </em>
                          で再試行してください。
                      </span>
                  </div>
                )}

            </div>

          </div>
        ) : (

      <div className="lay-login">
        <Modal isOpen={modalOpen} onClose={closeModal} message={modalMessage} />
        <div className="lay-login__sub">
          <img src="img/logo.png" alt="Logo" className="logo" />
          <div className="login-form">
            {showErrorMessage && errorMessage  && <p className="error" dangerouslySetInnerHTML={{ __html:purify.sanitize(errorMessage) }} />}
            
            <form onSubmit={handleLogin}>
             
              <label className="label-control">ログインID</label>

              <input type="text" className="form-control" value={loginId} onChange={(e) => setLoginId(e.target.value)} required />

              <label className="label-control">パスワード</label>
              
              <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
              
              <button type="submit" className="btn btn-primary" disabled={isSubmit} >
                ログイン
              </button>

            </form>
          </div>
        </div>
      </div>
    )}
    </div>
  );
}
);
Login.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  // otpToken: PropTypes.string,
  accessToken: PropTypes.string,
  refreshToken: PropTypes.string,
  errorCode: PropTypes.string,
  errorMessage: PropTypes.string,
  sendLoginInformation: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  CheckOtp: PropTypes.func.isRequired,
  sessionId: PropTypes.number, 

};

export default Login;
