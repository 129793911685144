import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import UserList from "../components/UserList";
import { fetchUsers } from "../actions/userlist";

const mapStateToProps = (state) => {
  const { userList } = state;
  console.log("userList:", userList);

  return {
    users: userList.users,
    loading: userList.loading,
    error: userList.error,
    alldatanumber: userList.alldatanumber,
    is_null: userList.is_null
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUsers
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserList);