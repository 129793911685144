import message from "../constants/message";

// 入力チェック
const formatMessage = (template, ...values) => {
    return values.reduce((formatted, value) => formatted.replace('%s', value), template);
} 

// const alphaNumericSymbols = (str) => {
//     return /^[!-~]+$/.test(str) && !/[¥\\/:*?"<>|%&]/.test(str);
// };

const alphaNumericSymbols = (str) => {
    return /^[a-zA-Z0-9_.-]{5,16}$/.test(str);
};

export function checkInput(text, minLength, maxLength, field) {
    if (!text.trim()) {
        return formatMessage(message.REQUIRED_MSG, field);
    }
    if (isNaN(minLength) || isNaN(maxLength)) {
        return null;
    }

    if (text.length < minLength) {
        return formatMessage(message.MIN_LENGTH_MSG, field, minLength);
    }
    if (text.length > maxLength) {
        return formatMessage(message.MAX_LENGTH_MSG, field, maxLength);
    }

    if (!alphaNumericSymbols(text)) {
        return formatMessage(message.ALPHA_NUMBERIC_SYMBOL_MSG, field);
    }
    return null; // Valid length
}

export function utf8ToBase64(str) {
    return btoa(String.fromCharCode(...new TextEncoder().encode(str)));
}

