
import React, { useState, useEffect,useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation ,useNavigate } from 'react-router-dom';
import './DeletedLogList.css';
import Modal from '../Modal/Modal';

//削除されたレコードのリストを表示します
const DeletedLogList = ({ deletedLog = [], loading, error, fetchDeletedLog,alldatanumber, is_null }) => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage] = useState('');
    const [search, setSearch] = useState({ company_code: '', company_name: '' });
    const [DeletedLogList, setDeletedLogList] = useState(deletedLog);
    const itemsPerPage = 20;
    const [pageindex] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    //API を呼び出して削除されたレコードのリストを取得します。
    useEffect(() => {
        setIsDataLoaded(false); 
        const query = new URLSearchParams(location.search);
        const page = parseInt(query.get('page'), 10) || 1;  
        setCurrentPage(page);
        setSearch({ company_code: '', company_name: '' });
    
        fetchDeletedLog(page, itemsPerPage)
            .finally(() => {
                setIsDataLoaded(true); 
            });
    }, [fetchDeletedLog, itemsPerPage, location]);
    
    
    useEffect(() => {
        setDeletedLogList(deletedLog.length > 0 ? deletedLog : []);
        // setIsLoading(false); 
    }, [deletedLog]);
    
    // 検索値の変更を処理します
    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    // 検索フォーム送信時のハンドル
    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();
        fetchDeletedLog(pageindex, itemsPerPage, search.company_code, search.company_name)
    };

    // 編集ページにリダイレクトします
    const handleDeletedLogEdit = useCallback((company_id) => {
        navigate(`/DeletedLogEdit?DeletedLogEdit=${company_id}&page=${currentPage}`);
    }, [navigate, currentPage]);
    
    //ページネーション
    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                        <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                            <span>{page}</span>
                        </div>
                    </li>
                    
                    ))}
                </ul>
            </div>
        );
    };

    const handleMenuClick = (page) => {
        if (page === currentPage) {
            fetchDeletedLog(page, itemsPerPage, search.company_code, search.company_name);
        }
        navigate(`?page=${page}`);
        setCurrentPage(page);
    };

    //ページ変更の処理
    const handlePageChange = (page) => {
        // navigate(`?page=${page}`);
        // setCurrentPage(page);
        // fetchDeletedLog(page, itemsPerPage, search.company_code, search.company_name);
        handleMenuClick (page);
    };
    
    return (
        <div id='Main' className='MainMail'  >
           <div id='Contents' className='ContentsMail' >
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}
                <div id="ajax-view-disp">
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>削除組織ログ一覧</span>
                                </div>

                                <form onSubmit={handleSearchSubmit}style={{ marginTop: '20px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>
                                </form>

                                <div className="lay-master-set__frame"style={{ marginTop: '15px' }}>
                                    <div className="lay-master__table">
                                        <form id="AdminList">
                                            <table>
                                                <thead>
                                                <tr>
                                                        <th className="col-deleted-cot1">組織ID</th>
                                                        <th className="col-deleted-cot2">組織名</th>
                                                        <th className="col-deleted-cot3">管理ユニットID</th>
                                                        <th className="col-deleted-cot4">管理ユニット名</th>
                                                        <th className="col-deleted-cot5">削除日</th>
                                                        <th className="col-deleted-cot6">詳細</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    { DeletedLogList.length > 0 && isDataLoaded === true && is_null === false ? (
                                                        DeletedLogList.map((val, index) => (
                                                            <tr key={index} className="lay-tr">
                                                                <td className="col-deleted-cot1" style={{ textAlign: 'left' }} >{val.company_code}</td>
                                                                <td className="col-deleted-cot2" style={{ textAlign: 'left' }} >{val.company_name}</td>
                                                                <td className="col-deleted-cot3" style={{ textAlign: 'left' }} >{val.manage_unit_id}</td>
                                                                <td className="col-deleted-cot4" style={{ textAlign: 'left' }} >{val.manage_unit_name}</td>
                                                                <td className="col-deleted-cot5">{val.update_date}</td>
                                                                <td className="col-deleted-cot6">
                                                                    <button
                                                                        type="button"
                                                                        id={`admin_info_edit_${index}`}
                                                                        className="mod-btn__companylist"onClick={() => handleDeletedLogEdit(val.company_id)} 
                                                                    >詳細
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="6" style={{ textAlign: "center", display: isDataLoaded && is_null ? '' : 'none'}}>
                                                                <div className="mod-no-data">表示データが存在しません。</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </form>

                                        {totalPages > 1 && isDataLoaded && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )}  

                                        {!isDataLoaded && (
                                            <div className="loading-container">
                                                <div className="overlay active">
                                                    <div className="spinner active">
                                                        <img id="waiting-img" src="img/loader.gif" alt="Loading..." />
                                                    </div>
                                                </div>
                                            </div>
                                        )}                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// propsのデータ型を確認する
DeletedLogList.propTypes = {
    deletedLog: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchDeletedLog: PropTypes.func.isRequired
};

export default React.memo(DeletedLogList);