import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FacilityList from "../components/FacilityList";
import { fetchFacility } from "../actions/facilitylist";

const mapStateToProps = (state) => {
  const { facilityList } = state;

  return {
    facility: facilityList.facility,
    loading: facilityList.loading,
    error: facilityList.error,
    alldatanumber: facilityList.alldatanumber,
    is_null: facilityList.is_null
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFacility
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FacilityList);