import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MaintenanceList from "../components/MaintenanceList";
import { fetchMaintenance, deletemaintenance, submitMaintenance, fetchDetail } from "../actions/maintenancelist";

const mapStateToProps = (state) => {
  return {
    maintenance: state.maintenanceList.maintenance,
    loading: state.maintenanceList.loading,
    error: state.maintenanceList.error,
    alldatanumber: state.maintenanceList.alldatanumber ,
    screenMessage: state.maintenanceList.detail ? state.maintenanceList.detail.screen_message : 'No message',
    is_null: state.deletedLoglist.is_null,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMaintenance,
      deletemaintenance,
      submitMaintenance,
      fetchDetail  
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceList);